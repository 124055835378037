import React, { Component } from 'react';
import ReactECharts from "@/components/EChartsReact";
import { NoDecimalFormat, SinglePercentFormat } from "@components/Format";
import _ from "lodash";

const percentFormat = (ratio) => {
    if (ratio != null) {
        return new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
        }).format(ratio);
    } else {
        return null
    }
}

class PieChart extends Component {
    getOption = (props) => {
        const total = Math.round((props.data ?? []).reduce((p, v) => p + v.value, 0));

        return {
            title: {
                text: this.props.title,
                left: 'center',
                top: '2%',
                textStyle: {
                    color: '#396598',
                    fontSize: 14
                }
            },
            tooltip: {
                trigger: 'item',
                formatter: (params) => {
                    if(!params.name) {
                        return
                    }
                    return `${params.name}: ${NoDecimalFormat(params.value)} (${percentFormat(params.percent / 100)})`
                }
            },
            grid: {
                top: '40%',
                bottom: '40%',

                containLabel: true
            },
            series: [
                {
                    type: 'pie',
                    radius: ['40%', '65%'],
                    center: ['50%', '55%'],
                    avoidLabelOverlap: true,
                    data: props.data.map(item => ({
                        name: item.type,
                        value: item.value,
                        itemStyle: {
                            color: props.colors?.[item.type] || undefined
                        }
                    })),
                    label: {
                        show: true,
                        formatter: function (params) {
                            return [
                                `{name|${params.name}}`,
                                `{value|${params.value} (${percentFormat(params.percent / 100)})}`
                            ].join('\n');
                        },
                        alignTo: 'edge',
                        position: 'outside',
                        distanceToLabelLine: 20,
                        edgeDistance: '5%',
                        rich: {
                            name: {
                                fontSize: 14,
                                fontWeight: 'bold',
                                color: '#333',
                                padding: [0, 0, 5, 0],
                                lineHeight: 20
                            },
                            value: {
                                fontSize: 12,
                                color: '#666',
                                lineHeight: 16
                            }
                        }
                    },
                    labelLine: {
                        show: true,
                        smooth: true,
                        length: 15,
                        length2: 0,
                        maxSurfaceAngle: 80
                    },
                    labelLayout: function (params) {
                        const isLeft = params.labelRect.x < window.innerWidth / 2;
                        const points = params.labelLinePoints;
                        points[2][0] = isLeft
                            ? params.labelRect.x
                            : params.labelRect.x + params.labelRect.width;
                        return {
                            labelLinePoints: points
                        };
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                },
                {
                    type: 'pie',
                    radius: ['0%', '30%'],
                    center: ['50%', '55%'],
                    data: [{
                        value: 100,
                        name: '',
                        itemStyle: {
                            color: 'transparent'
                        },
                        label: {
                            show: true,
                            position: 'center',
                            formatter: () => {
                                return `{title|Total}\n{total|${total}}`;
                            },
                            rich: {
                                title: {
                                    fontSize: 16,
                                    fontWeight: 600,
                                    padding: [0, 0, 10, 0]
                                },
                                total: {
                                    fontSize: 24,
                                    fontWeight: 600,
                                    padding: [5, 0, 0, 0]
                                }
                            }
                        }
                    }]
                }
            ]
        };
    };

    onChartClick = (params) => {
        if(typeof this.props.handleChartClick === 'function') {
            this.props.handleChartClick(params)
        }
    };

    render() {
        const { height, title } = this.props;

        return (
            <ReactECharts
                style={{ height: '100%', width: '100%' }}
                option={this.getOption(this.props)}

                onEvents={{
                    'click': this.onChartClick
                }}
            />
        );
    }
}

export default PieChart;

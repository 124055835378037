import React from "react";
import EChartsReact from "@components/EChartsReact";
import LayoutContent from "@components/utility/layoutContent";
import _ from "lodash";

const WorkOrderChart = (props) => {
  const {
    data,
    height,
    isStack,
    type,
    value,
    seriesField,
    colors,
    title,
    handleChartClick,
  } = props;

  // 获取所有唯一的系列名称
  const seriesNames = [...new Set(data.map((item) => item[seriesField]))];

  // Aggregate data by date and status
  const aggregatedData = _.chain(data)
    .groupBy(type)
    .mapValues((dateGroup) =>
      _.chain(dateGroup)
        .groupBy(seriesField)
        .mapValues((statusGroup) => _.sumBy(statusGroup, value))
        .value()
    )
    .map((dateValues, date) => {
      return seriesNames.map((status) => ({
        [type]: date,
        [seriesField]: status,
        [value]: dateValues[status] || 0,
      }));
    })
    .flatten()
    .value();

    const option = {
        backgroundColor: '#fff',
        title: {
            text: title,
            left: 'center',
            top: '2%',
            textStyle: {
                color: '#396598',
                fontSize: 14
            }
        },
        legend: {
            data: seriesNames,
            left: 'center',
            top: '10%'
        },
        dataZoom: [
            {
                type: 'slider',
                show: true,
                xAxisIndex: [0],
                start: 0,
                end: 100,
                bottom: '2%'
            },
            {
                type: 'inside',
                xAxisIndex: [0],
                start: 0,
                end: 100
            }
        ],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        xAxis: {
            type: 'category',
            data: [...new Set(aggregatedData.map(item => item[type]))],
            axisLabel: {
                interval: 'auto',
                // rotate: 30
            }
        },
        yAxis: {
            type: 'value',
            name: 'Num of workorder created',
            nameRotate: 90,
            nameLocation: 'middle',
            nameTextStyle: {
                padding: [0, 0, 20, 0]
            }
        },
        series: seriesNames.map(name => ({
            name: name,
            type: 'bar',
            stack: isStack ? 'total' : undefined,
            data: aggregatedData
                .filter(item => item[seriesField] === name)
                .map(item => item[value]),
            itemStyle: {
                color: colors?.[name] || undefined
            }
        })),
        grid: {
            top: '20%',
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
        }
    };

  const onEvents = {
    click: (params) => {
      const clickedData = aggregatedData.find(
        (item) =>
          item[type] === params.name && item[seriesField] === params.seriesName
      );
      if (clickedData && handleChartClick) {
        handleChartClick(clickedData);
      }
    },
  };

  return (
    <EChartsReact
      option={option}
      style={{ height: "100%", width: "100%" }}
      onEvents={onEvents}
    />
  );
};

export default WorkOrderChart;

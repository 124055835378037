import { Timeline, Collapse, Tag, Pagination, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { api } from "../../redux/api";
import styled from "styled-components";

const { Panel } = Collapse;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TimelineItem = styled.div`
  position: relative;
  padding: 6px 0;

  &::before {
    content: "";
    position: absolute;
    left: -16px;
    top: 50%;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${(props) => {
      switch (props.color) {
        case "darkgreen":
          return "#52c41a";
        case "darkred":
          return "#ff4d4f";
        case "darkblue":
          return "#3f80ff";
        default:
          return "#d9d9d9";
      }
    }};
    transform: translateY(-50%);
    box-shadow: 0 0 0 3px
      rgba(
        ${(props) => {
          switch (props.color) {
            case "darkgreen":
              return "82,196,26";
            case "darkred":
              return "255,77,79";
            case "darkblue":
              return "63,128,255";
            default:
              return "217,217,217";
          }
        }},
        0.12
      );
  }

  &::after {
    content: "";
    position: absolute;
    left: -14px;
    top: 50%;
    height: 100%;
    width: 1px;
    background: #e8e8e8;
  }
`;

const LogCard = styled.div`
  background: white;
  border-radius: 6px;
  padding: 12px 16px;
  margin-left: 16px;
  transition: all 0.2s ease;
  border: 1px solid #f0f0f0;
  cursor: pointer;

  &:hover {
    background: rgba(63, 128, 255, 0.02);
    border-color: #3f80ff;
  }
`;

const LogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => (props.isExpanded ? "12px" : "0")};
  pointer-events: none;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .user-avatar {
    width: 28px;
    height: 28px;
    background: #f5f5f5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3f80ff;
    font-size: 14px;
  }

  .user-info-text {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .user-name {
    font-weight: 500;
    color: #1d578f;
    font-size: 13px;
  }

  .user-module {
    color: #8c8c8c;
    font-size: 12px;
  }
`;

const MessageText = styled.div`
  color: #595959;
  font-size: 13px;
  margin-top: ${(props) => (props.isExpanded ? "12px" : "4px")};
  margin-left: 38px;
  line-height: 1.5;
`;

const LogDetails = styled.div`
  background: #fafafa;
  border-radius: 6px;
  padding: 12px 16px;
  margin-top: 8px;
  font-size: 13px;
  border: 1px solid #f0f0f0;

  .detail-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #f0f0f0;

    &:last-child {
      border-bottom: none;
    }

    .key {
      color: #595959;
      font-weight: 500;
      margin-right: 24px;
      min-width: 140px;
    }

    .value {
      color: #262626;
      word-break: break-all;
      text-align: right;
      flex: 1;
    }
  }
`;

const StyledTag = styled(Tag)`
  border-radius: 4px;
  padding: 0 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  border: none;
  margin-right: 0;

  &.ant-tag-darkgreen {
    color: #52c41a;
    background: #f6ffed;
  }

  &.ant-tag-darkred {
    color: #ff4d4f;
    background: #fff1f0;
  }

  &.ant-tag-darkblue {
    color: #3f80ff;
    background: #e6f0ff;
  }
`;

const TimeButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: #8c8c8c;
  padding: 6px;
  display: flex;
  align-items: center;
  transition: all 0.2s;
  border-radius: 4px;
  font-size: 14px;

  &:hover {
    background: #f5f5f5;
    color: #3f80ff;
  }
`;

const TimeInfo = styled.span`
  color: #8c8c8c;
  font-size: 13px;
`;

const StyledPagination = styled(Pagination)`
  .ant-pagination-item {
    border-radius: 6px;
    margin: 0 4px;
    min-width: 32px;
    height: 32px;
    line-height: 30px;
    border-color: #f0f0f0;
    transition: all 0.2s;

    a {
      color: #595959;
    }

    &:hover {
      border-color: #3f80ff;
      a {
        color: #3f80ff;
      }
    }
  }

  .ant-pagination-item-active {
    border-color: #3f80ff;
    background: #fff;

    a {
      color: #3f80ff;
      font-weight: 500;
    }
  }

  .ant-pagination-options {
    margin-left: 12px;
  }
`;

const getTagColor = (actionType) => {
  switch (actionType) {
    case "CREATE":
      return "darkgreen"; // Green
    case "DELETE":
      return "darkred"; // Red
    case "UPDATE":
      return "darkblue"; // Blue
    default:
      return "#d9d9d9"; // Default color
  }
};

const getSafeResponse = (response) => {
  if (Array.isArray(response?.data)) {
    return response.data;
  } else if (Array.isArray(response?.data?.results)) {
    return response.data.results;
  }
  return [];
};

const RecordAuditTimeline = ({ moduleId, module }) => {
  const [data, setData] = useState([]);
  const [expandedItem, setExpandedItem] = useState(null);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  // handleFilter method fetches audit logs based on parentRecordId
  const handleFilter = async (page, size) => {
    try {
      setLoading(true);
      var query = `&page=${page}&page_size=${size}`;
      if (module !== "All" && moduleId !== "All") {
        query = `module=${module}&moduleId=${moduleId}` + query;
      }
      const response = await api.getLog(query);
      let dataSource = getSafeResponse(response);
      setTotal(response.data?.count);
      setData(dataSource);
    } catch (error) {
      console.error("Error fetching logs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (moduleId) {
      handleFilter(currentPage, pageSize);
    }
  }, [moduleId, currentPage, pageSize]);

  const renderDetails = (record) => {
    const { type, createdby_name, message, ...details } = record;

    return Object.entries(details).map(([key, value], idx) => {
      if (key === "params") {
        try {
          const parsedValue = JSON.parse(value);
          return Object.entries(parsedValue).map(
            ([paramKey, paramValue], paramIdx) => (
              <div className="detail-item" key={`${idx}_${paramIdx}`}>
                <span className="key">{paramKey}</span>
                <span className="value">
                  {typeof paramValue === "object"
                    ? JSON.stringify(paramValue)
                    : paramValue}
                </span>
              </div>
            )
          );
        } catch (e) {
          return (
            <div className="detail-item" key={idx}>
              <span className="key">{key}</span>
              <span className="value">{value}</span>
            </div>
          );
        }
      }

      return (
        <div className="detail-item" key={idx}>
          <span className="key">{key}</span>
          <span className="value">
            {typeof value === "object" ? JSON.stringify(value) : value}
          </span>
        </div>
      );
    });
  };

  const handleExpand = (index) => {
    if (expandedItem === index) {
      setExpandedItem(null);
    } else {
      setExpandedItem(index);
    }
  };

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  return (
    <Spin spinning={loading} tip="Loading...">
      <div style={{ padding: "20px" }}>
        {(data ?? []).map((record, index) => {
          const { message, createdby_name, type, created_date, module } =
            record;
          const tagColor = getTagColor(type);
          const isExpanded = expandedItem === index;

          return (
            <TimelineItem key={index} color={tagColor}>
              <LogCard onClick={() => handleExpand(index)}>
                <LogHeader isExpanded={isExpanded}>
                  <UserInfo>
                    <div className="user-avatar">
                      <i className="fa-solid fa-user"></i>
                    </div>
                    <div className="user-info-text">
                      <span className="user-name">{createdby_name}</span>
                      <span className="user-module">{module}</span>
                    </div>
                    <StyledTag color={tagColor}>{type}</StyledTag>
                  </UserInfo>
                  <TimeInfo>
                    {moment(created_date).format("YYYY-MM-DD HH:mm:ss")}
                  </TimeInfo>
                </LogHeader>
                <MessageText isExpanded={isExpanded}>
                  {message || "No message provided"}
                </MessageText>
                {isExpanded && <LogDetails>{renderDetails(record)}</LogDetails>}
              </LogCard>
            </TimelineItem>
          );
        })}
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <StyledPagination
            current={currentPage}
            total={total}
            pageSize={pageSize}
            size="small"
            showSizeChanger
            onShowSizeChange={handlePageChange}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </Spin>
  );
};

export default RecordAuditTimeline;

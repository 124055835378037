import Input from "antd/es/input";
import React from "react";
import FunctionalTestingFieldsEditor
    from "@containers/facility/Equipment/FunctionalTesting/FunctionalTestingFieldsEditor";
import CustomDatePicker from "@components/CustomDatePicker";
import {store} from "@redux/store";
import {InputNumber} from "antd";
import FunctionalTestFormEquipmentLookup from "../../../Lookup/FunctionalTestFormEquipmentLookup";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

const user = store.getState().Auth.user

export const FunctionalTestingFormFields = (formRef, selectedTypeOption, selected_record, selectedTemplateType) => {
    // console.log('selected_record:',selectedTypeOption, selected_record, selectedTemplateType)
    return [
        {
            title: 'key',
            name: 'id',
            order: 0,
            visible: false,
            length: 0,
            type: 'text',
        },
        {
            title: 'equipment',
            name: 'equipment',
            order: 0,
            visible: false,
            length: 0,
            type: 'text',
        },
        {
            title: 'equipment',
            name: 'equipment__id',
            order: 0,
            visible: false,
            length: 0,
            type: 'text',
        },
        {
            title: 'Equipment',
            name: 'equipment',
            order: 5,
            required: false,
            visible:  true,
            length: " col-span-2",
            type: 'Lookup',
            render: (value) => {
                return <FunctionalTestFormEquipmentLookup formRef={formRef} record={value}
                                                          selectedTemplateType={selectedTemplateType}
                                                          selected_record={selected_record}/>
            },
            readOnlyRender(data) {
                return data.equipment_name
            }
        },
        {
            title: 'Summary',
            name: 'description',
            order: 0,
            visible: true,
            length: "col-span-2 row-span-5 p-2 ",
            type: 'text',
            render:
                <TextArea rows={8} />
        },
        {
            title: 'Test Date',
            name: 'record_date',
            required: true,
            order: 0,
            visible: true,
            length: " col-span-2",
            render: <CustomDatePicker format='YYYY-MM-DD'/>,
            readOnlyRender(data) {
                return data.record_date ? moment(data.record_date).format('YYYY-MM-DD HH:mm:ss') : ''
            }
        },
        {
            title: 'Test By',
            name: 'record_by',
            order: 0,
            visible: true,
            length: " col-span-2",
            type: 'text',
            render:
                <Input/>,
            readOnlyRender(data) {
                return data.record_by_name
            }
        },
        {
            title: 'OA Temp (°F)',
            name: 'oa_temp',
            order: 0,
            visible: true,
            length: "w-full col-span-2",
            type: 'text',
            render:
                <InputNumber/>,
            readOnlyRender(data) {
                return data.oa_temp
            }
        },
        {
            title: 'OA RH (%)',
            name: 'oa_rh',
            order: 0,
            visible: true,
            length: "w-full col-span-2",
            type: 'text',
            render:
                <InputNumber/>,
            readOnlyRender(data) {
                return data.oa_rh
            }
        },
        {
            title: '',
            name: 'value',
            order: 8,
            visible: true,
            length: "w-full col-span-4",
            type: 'customJSON',
            render: (value) => {
                return (<>{<FunctionalTestingFieldsEditor value={selectedTypeOption ? selectedTypeOption : value}
                                                          formRef={formRef}
                />}</>)
            }
        },

        {
            title: 'Created By',
            name: 'createdby_name',
            order: 17,
            visible: true,
            length: "col-span-1",
            type: 'text',
            render: <Input disabled/>
        },

        {
            title: 'Created Date',
            name: 'created_date',
            order: 18,
            visible: true,
            length: "col-span-1",
            type: 'Date',
            render: <Input disabled/>
        },
        {
            title: 'Modified By',
            name: 'lastModified_name',
            order: 19,
            visible: true,
            length: "col-span-1",
            type: 'text',
            render: <Input disabled/>
        },
        {
            title: 'Modified Date',
            name: 'modified_date',
            order: 20,
            visible: true,
            length: "col-span-1",
            type: 'Date',
            render: <Input disabled/>
        },

    ]
}

import React, { useEffect, useRef } from "react";
import { Table } from "antd";
import styled from "styled-components";
import { useSummaryData } from "../hooks/useSummaryData";
import moment from "moment";
import { ClassName_KEY_STATISTICS } from "./summaryChartClassnames";

export const config = {
  title: "Key Statistics",
};

const StyleTable = styled(Table)`
  td {
    border: 0 !important;
    padding: 12px 16px !important;
    font-size: 14px;
    color: #2c3e50;
  }

  .ant-table {
    background: transparent;
  }

  .ant-table-thead > tr > th {
    background: rgba(57, 101, 152, 0.08) !important;
    color: #396598;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 2px solid rgba(57, 101, 152, 0.2);
    transition: all 0.3s ease;
  }

  .ant-table-thead > tr > th:hover {
    background: rgba(57, 101, 152, 0.12) !important;
  }

  .ant-table-tbody > tr {
    transition: all 0.3s ease;
  }

  .ant-table-tbody > tr:hover {
    transform: translateX(5px);
    background: rgba(57, 101, 152, 0.02) !important;
  }

  .ant-table-tbody > tr > td {
    transition: all 0.3s ease;
    border-bottom: 1px solid rgba(57, 101, 152, 0.05) !important;
  }

  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: linear-gradient(90deg, #396598 0%, #4b7ab3 100%) !important;
    color: #fff !important;
    cursor: pointer !important;
    box-shadow: 0 2px 8px rgba(57, 101, 152, 0.2);
  }

  .ant-table-tbody > tr:nth-child(even) {
    background: rgba(57, 101, 152, 0.02);
  }

  // 高亮显示重要数据
  .ant-table-tbody > tr:nth-child(7) td,  // Total Cost
  .ant-table-tbody > tr:nth-child(9) td {
    // EUI
    font-weight: 600;
    background: rgba(57, 101, 152, 0.04);
  }
`;

const columns = [
  {
    title: <div style={{ padding: "0 10px", fontWeight: "bold" }}>Name</div>,
    dataIndex: "name",
    render(text) {
      return <span style={{ padding: "0 10px" }}>{text}</span>;
    },
  },
  {
    title: <div style={{ fontWeight: "bold" }}>Value</div>,
    dataIndex: "value",
    align: "right",
  },
  {
    title: <div style={{ paddingLeft: "10px", fontWeight: "bold" }}>Unit</div>,
    dataIndex: "unit",
    render(text) {
      return <span style={{ paddingLeft: "10px" }}>{text}</span>;
    },
  },
];

const formatNumber = (value) => {
  if (value === "-" || value == null) return "-";
  return value.toLocaleString("en-US", { maximumFractionDigits: 0 });
};

const formatCost = (value) => {
  if (value === "-" || value == null) return "-";
  return value.toLocaleString("en-US", { maximumFractionDigits: 0 });
};

const formatThreeDecimals = (value) => {
  if (value === "-" || value == null) return "-";
  return value.toLocaleString("en-US", {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });
};

export const KeyStatistics = ({ period, endingMonth }) => {
  const ref = useRef(null);

  const {
    data: electricityData = {
      electricity: [],
    },
  } = useSummaryData("electricity", period, endingMonth);
  const {
    data: gasData = {
      gas: [],
    },
  } = useSummaryData("gas", period, endingMonth);

  const start_month = moment(endingMonth)
    .subtract(period - 1, "month")
    .startOf("month")
    .format("YYYY-MM");
  const end_month = moment(endingMonth).endOf("month").format("YYYY-MM");

  const dataSource = [
    {
      name: "Total Electricity Usage",
      value: formatNumber(electricityData.total_usage || "-"),
      unit: "kWh",
    },
    {
      name: "Total Electricity Cost",
      value: "$ " + formatCost(electricityData.total_cost || "-"),
      unit: "",
    },
    {
      name: "Average Electricity Price",
      value: electricityData.total_usage
        ? "$ " +
          formatThreeDecimals(
            Number(
              (
                electricityData.total_cost / electricityData.total_usage
              ).toFixed(3)
            )
          )
        : "-",
      unit: "per kWh",
    },
    {
      name: "Total Gas Usage",
      value: formatCost(gasData.total_usage || "-"),
      unit: "MMBtu",
    },
    {
      name: "Total Gas Cost",
      value: "$ " + formatCost(gasData.total_cost || "-"),
      unit: "",
    },
    {
      name: "Average Gas Price",
      value: gasData.total_usage
        ? "$ " +
          formatThreeDecimals(
            Number((gasData.total_cost / gasData.total_usage).toFixed(3))
          )
        : "-",
      unit: "per MMBtu",
    },
    {
      name: "Total Cost",
      value: "$ " + formatCost(electricityData.total_cost + gasData.total_cost),
      unit: "",
    },
    {
      name: "Total Cost per Sqft per year",
      value:
        "$ " +
        formatThreeDecimals(
          ((gasData.total_area_cost + electricityData.total_area_cost) * 12) /
            period
        ),
      unit: "per sqft-yr",
    },
    {
      name: "Energy Use Intensity (EUI)",
      value: formatNumber(
        Math.round(
          (electricityData?.annual_eui || 0) + (gasData?.annual_eui || 0)
        )
      ),
      unit: "kBtu/sqft-yr",
    },
  ];

  useEffect(() => {
    if (ref.current) {
      ref.current.__tableData__ = {
        dataSource,
        columns,
        title: config.title,
        start_month,
        end_month,
      };
    }
  }, [dataSource]);

  return (
    <div ref={ref} className={ClassName_KEY_STATISTICS}>
      <div
        style={{
          fontSize: 16,
          fontWeight: 600,
          background: "linear-gradient(135deg, #396598 0%, #4b7ab3 100%)",
          color: "#fff",
          borderRadius: "12px 12px 0 0",
          padding: "16px 24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0 2px 12px rgba(57, 101, 152, 0.15)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "radial-gradient(circle at 0% 0%, rgba(255,255,255,0.1) 0%, transparent 50%)",
          }}
        />
        <span
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <svg
            viewBox="0 0 24 24"
            width="20"
            height="20"
            fill="currentColor"
            style={{ opacity: 0.9 }}
          >
            <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z" />
            <path d="M7 12h2v5H7zm4-7h2v12h-2zm4 4h2v8h-2z" />
          </svg>
          {config.title}
        </span>
        <span
          style={{
            fontSize: 14,
            fontWeight: "normal",
            opacity: 0.9,
            position: "relative",
            background: "rgba(255,255,255,0.1)",
            padding: "4px 12px",
            borderRadius: "6px",
          }}
        >
          {start_month} - {end_month}
        </span>
      </div>
      <div
        style={{
          background: "#fff",
          borderRadius: "0 0 12px 12px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.06)",
        }}
      >
        <StyleTable
          size="middle"
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
};

export const PdfKeyStatistics = ({
  dataSource,
  columns,
  title,
  start_month,
  end_month,
}) => {
  return (
    <p-stack>
      <p-table widths={["*"]} border={0} layout="noBorders">
        <p-tr fillColor="#396598" color="#ffffff" fontSize={15}>
          <p-td>{`${title} (${start_month} - ${end_month})`}</p-td>
        </p-tr>
      </p-table>
      <p-table widths={["*", "auto", "auto"]}>
        <p-th>
          {columns.map((item, index) => {
            return <p-td bold={true}>{item.title.props.children}</p-td>;
          })}
        </p-th>
        {dataSource.map((d, dIdx) => {
          return (
            <p-tr key={dIdx}>
              {columns.map((c, cIdx) => {
                if (c.dataIndex === "value") {
                  return (
                    <p-td key={cIdx} alignment="right">
                      {d[c.dataIndex]}
                    </p-td>
                  );
                }
                return <p-td key={cIdx}>{d[c.dataIndex]}</p-td>;
              })}
            </p-tr>
          );
        })}
      </p-table>
    </p-stack>
  );
};

import React from "react"
import { isImage, isDoc, isMedia, isSupportIframe, isAudio } from "./utils"
import DocViewer, { DocViewerRenderers } from "react-doc-viewer"
import { Image, Tag } from "antd"
import moment from "moment"
const getViewElement = (url) => {
  if (!url) {
    return <div>Loading...</div>
  }
  if (isImage(url)) {
    return <Image rootClassName="previewImage" style={{ width: '100%', height: '100%' }} src={url} alt="errorimage" />
  } else if (isDoc(url)) {
    return <DocViewer pluginRenderers={DocViewerRenderers}
      style={{ width: '100%', height: '80vh' }}
      prefetchMethod="GET"
      documents={[
        { uri: url, fileType: 'pptx' }
      ]} />
  } else if (isMedia(url)) {
    return <video style={{ width: '100%', height: '100%' }} src={url} />
  } else if (isSupportIframe(url)) {
    return <iframe style={{ width: '100%', height: '100%' }} src={url} title="preview-file" />
  } else if (isAudio(url)) {
    return <audio controls src={url}></audio>
  } else {
    return <div style={{ width: '100%', height: '100%' }}>There is no preview.</div>
  }
}

export const FileViewer = ({ url = '', currentOpenFile }) => {
  if(currentOpenFile?.isFolder) {
   return <div style={{ textAlign: 'center' }}>
      {currentOpenFile?.isFolder === false ? getViewElement(url) : 'There is no preview!'}
    </div>
  }
  return (
    <div style={{ width: '100%', height: '86vh', display: 'flex', flexDirection: 'column' }}>
      <div style={{ textAlign: 'center', flex: 1 }}>
        {currentOpenFile?.isFolder === false ? getViewElement(url) : 'There is no preview!'}
      </div>
      <div style={{ textAlign: 'left', marginTop: 10 }}>
        {Array.isArray(currentOpenFile?.tags_list) && (currentOpenFile?.tags_list.length > 0) && <>
          Tags:
          <span style={{ marginLeft: 10 }}>
            {currentOpenFile.tags_list.map(item => <Tag color="blue" key={item}>{item}</Tag>)}
          </span>
        </>}
      </div>
      <div style={{textAlign: 'left', marginTop: 10}}>
        ModifiedBy: {currentOpenFile?.lastModified_name}
      </div>
      <div style={{textAlign: 'left', marginTop: 10}}>
        ModifiedDate: {moment(currentOpenFile?.modified_date).format('MM/DD/YYYY')}
      </div>
    </div>
  );
}

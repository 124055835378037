import React, {useEffect, useState} from 'react';
import {Button, ConfigProvider, Input, Select, Space, Table, Upload} from "antd";
import {MinusCircleOutlined, PlusCircleOutlined, UploadOutlined} from "@ant-design/icons";
import {api} from "@redux/api";
import enUSIntl from "antd/lib/locale/en_US";
import Modal from "antd/es/modal/Modal";
import DocViewer, {DocViewerRenderers} from "react-doc-viewer";
import { ENV } from '@config/env'
import cloneDeep from "lodash/cloneDeep";
import styled from 'styled-components';
import TextArea from "antd/es/input/TextArea";
const { backendUrl } = ENV;

const {Option} = Select;

const FunctionalTestingFieldsWrapper = styled.div`
    .ant-table table{
        table-layout: auto !important;
    }
    margin-top:10px;
    margin-bottom:10px;
`

const FunctionalTestingFieldsEditor = ({value, formRef}) => {
    window.f  = formRef;

    const [data, setData] = useState([]);
    const [preview_visible, setPreviewVisible] = useState(false);
    const [preview_url, setPreviewUrl] = useState(null);
    const [preview_type, setPreviewType] = useState(null);
    const [preview_name, setPreviewName] = useState(null);
    const [raw_url, setRawUrl] = useState(null);

    useEffect(() => {
        setData(value ? value : []);
        formRef.setFieldsValue({
            value
        })
    }, [value])

    const handleDelete = (index) => {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
        formRef.setFieldsValue({
            value: newData
        })
    }

    const handleAdd = (index) => {
        const newData = [...data];
        const newRecord = {
            id: Date.now(),
            name: "",
            description: "",
            type: "",
            SetPoint: "",
            BMSValue: "",
            MeasuredValue: "",
            Acceptable: "",
            Comment: "",
            Image: null
        };
        newData.splice(index + 1, 0, newRecord);
        setData(newData);
    }

    const handleUploadChange = (info, index) => {
        let fileList = [...info.fileList];

        // Limit the number of uploaded files
        // Only show the last uploaded one, you can remove this if you want to show all
        fileList = fileList.slice(-1);

        // Check if the server returned a URL for the uploaded file
        // Update the file's URL if it exists
        fileList = fileList.map(file => {
            if (file.response && file.response.url) {
                // Extract the presigned URL from the server's response
                file.url = file.response.url.split("?")[0];
            }
            return file;
        });

        console.log('fileList: ', fileList, index)

        setData(prevData => {
            const newData = [...prevData];
            newData[index].Image = fileList[0];
            return newData;
        });
    };

    const handlePreview = async (record) => {
        let image = record.Image;
        let image_url_short = image?.url?.split('https://facilitykpiclientfile.s3.us-east-2.amazonaws.com/facilitykpiclientfile/')[1];
        let generate_url = await api.generate_url(image_url_short, true).then(
            response => {
                let dataSource = response.data.url
                return dataSource
            }
        ).catch(error => (
            error
        ));

        setPreviewUrl(generate_url);
        setPreviewType(image?.type);
        setPreviewName(image?.name);
        setPreviewVisible(true);
        setRawUrl(image_url_short);

    }

    const handleUrlDownload = async () => {
        let image_url_short = raw_url;
        let name = preview_name;
        let generate_url = await api.generate_url(image_url_short, false).then(
            response => {
                let dataSource = response.data.url
                return dataSource
            }
        ).catch(error => (
            error
        ));

        console.log('generate_url', raw_url, generate_url)

        const link = document.createElement('a');
        link.href = generate_url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    }


    const handleInput = (key, index, e) => {
        const value = e?.target?.value || e;
        const updatedData = cloneDeep(data);

        updatedData[index][key] = value;

        setData(updatedData);

        formRef.setFieldsValue({
            value: updatedData
        })
    };

    const columns = [

        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: '10%',
            render: (text, _, index) => (
                <Input
                    defaultValue={text}
                    onChange={(e) => handleInput('type', index, e)}
                />
            )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '10%',
            render: (text, _, index) => (
                <TextArea
                    rows={1}
                    defaultValue={text}
                    onChange={(e) => handleInput('name', index, e)}
                />
            )
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: '20%',
            render: (text, _, index) => (
                <TextArea
                    rows={1}
                    defaultValue={text}
                    onChange={(e) => handleInput('description', index, e)}
                />
            )
        },
        {
            title: 'Set Point',
            dataIndex: 'SetPoint',
            key: 'SetPoint',
            width: '6%',
            render: (text, _, index) => (
                <Input
                    defaultValue={text}
                    onChange={(e) => handleInput('SetPoint', index, e)}
                />
            )
        },
        {
            title: 'BMS Value',
            dataIndex: 'BMSValue',
            key: 'BMSValue',
            width: '6%',
            render: (text, _, index) => (
                <Input
                    defaultValue={text}
                    onChange={(e) => handleInput('BMSValue', index, e)}
                />
            )
        },
        {
            title: 'Measured',
            dataIndex: 'MeasuredValue',
            key: 'MeasuredValue',
            width: '6%',
            render: (text, _, index) => (
                <Input
                    defaultValue={text}
                    onChange={(e) => handleInput('MeasuredValue', index, e)}
                />
            )
        },
        {
            title: 'Acceptable',
            dataIndex: 'Acceptable',
            key: 'Acceptable',
            width: '6%',
            render: (text, _, index) => (
                <Select defaultValue={text} className={text === 'NO' ? 'selected_row_orange' : ''}
                        onChange={(e) => handleInput('Acceptable', index, e)}>
                    <Option value="YES">YES</Option>
                    <Option value="NO">NO</Option>
                </Select>
            )
        },
        {
            title: 'Comment',
            dataIndex: 'Comment',
            key: 'Comment',
            width: '20%',
            render: (text, _, index) => (
                <TextArea
                    rows={1}
                    defaultValue={text}
                    onChange={(e) => handleInput('Comment', index, e)}
                />
            )
        },
        // {
        //     title: 'Image',
        //     dataIndex: 'Image',
        //     key: 'Image',        
        //     width: '4%',
        //     render: (text, record, index) => (
        //         <div className={'flex'}>
        //             <Upload name="file" listType="picture-circle" action={backendUrl + `/upload_to_s3`}
        //                     onChange={(e) => handleUploadChange(e, index)}>
        //                 <Button icon={<UploadOutlined/>} className={'mx-4'}></Button>
        //             </Upload>

        //             {text&&<Button icon={<i className="fas fa-paperclip px-4 text-orange-600"></i>}
        //                         onClick={() => handlePreview(record)} >

        //                 </Button>}

        //         </div>
        //     )
        // },
        {
            title: 'Actions',
            key: 'actions',
            width: '5%',
            render: (text, record, index) => (
                <div className={'flex'}>
                    <Button type={'primary'} icon={<PlusCircleOutlined/>} shape={'circle'}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleAdd(index);
                            }}
                    ></Button>
                    <Button icon={<MinusCircleOutlined/>} shape={'circle'}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(index);
                            }}
                    ></Button>
                </div>
            )
        },
        {
            title: 'key',
            dataIndex: 'id',
            key: 'id',
            width: '0%',
            className: 'hidden'
        },
    ];
    console.log('data', data, value)
    return (
        <FunctionalTestingFieldsWrapper>
            <Table columns={columns} className={'table-auto'}
                   dataSource={data} pagination={false} rowKey="id"/>
            <Button type={'primary'} onClick={() => setData([...data, {
                id: Date.now(),
                name: "",
                type: "",
                SetPoint: "",
                BMSValue: "",
                MeasuredValue: "",
                Acceptable: "",
                Comment: "",
                Image: null
            }])}>
                Add New Field
            </Button>

            <ConfigProvider locale={enUSIntl}>
                <Modal width={'100%'}
                       title={<div className="flex justify-content">
                           <Button type={'primary'} onClick={() =>
                               handleUrlDownload(preview_name, raw_url)}>
                               <i className="fas fa-download pr-2" style={{fontSize: '14px'}}></i>
                               Download
                           </Button>
                       </div>}
                       bodyStyle={{height: 1000}}
                       footer={false}
                       visible={preview_visible}
                       onCancel={() => setPreviewVisible(false)}
                >

                    {
                        preview_type?.startsWith('image') || preview_type?.endsWith('pdf') ?
                            <iframe src={preview_url} width={'100%'} height={'100%'}/> :
                            <DocViewer pluginRenderers={DocViewerRenderers}
                                       style={{width: '100%', height: '100%'}}
                                       prefetchMethod="GET"
                                       documents={[
                                           {uri: preview_url, fileType: preview_type}
                                       ]}/>
                    }


                </Modal>
            </ConfigProvider>

        </FunctionalTestingFieldsWrapper>
    )
}

export default FunctionalTestingFieldsEditor;

import React from "react"
import { List, Checkbox } from "antd"
import { FileIcon, FolderIcon } from './Icon';
import classNames from 'classnames'


const listStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: 200,

};

export const CardTable = ({ dataSource, selectedRowKeys, setSelectedRowKeys, children, onRow }) => {

    return (
        <List
            style={{
                ...listStyle,
                justifyContent: dataSource.length > 0 ? 'flex-start' : 'center'
            }}
            dataSource={dataSource}
            renderItem={item => {
                const isSelected = selectedRowKeys.find(i => i.key === item.key)
                return (
                    <List.Item
                        onDoubleClick={(e) => {
                            onRow(item).onDoubleClick(e)
                        }}
                        onClick={(e) => {
                            onRow(item).onClick(e)
                        }}
                        onContextMenu={(e) => {
                            onRow(item).onContextMenu(e)
                        }}
                        style={{
                            width: 94,
                            height: 94,
                            display: 'inline-block',
                            borderRadius: 4,
                            border: 0,
                            margin: '8px 4px',
                            overflow: 'hidden',
                            position: 'relative',
                            cursor: 'pointer',
                            backgroundColor: isSelected ? '#1c578f' : 'transparent'
                        }}>
                        <div className={classNames("flex flex-col items-center text-center")}>
                            <div className="absolute top-1 left-2">
                                {/* <Checkbox checked={!!selectedRowKeys.find(i => {
                                        return i.key === item.key
                                    })} onChange={(e) => {
                                        const isChecked = e.target.checked;
                                        const newSelectedRowKeys = [...selectedRowKeys];
                                        if (isChecked) {
                                            newSelectedRowKeys.push(item)
                                        } else {
                                            const deleteIndex = newSelectedRowKeys.findIndex(i => i.key === item.key)
                                            newSelectedRowKeys.splice(deleteIndex, 1)
                                        }
                                        setSelectedRowKeys(newSelectedRowKeys)
                                    }} /> */}
                            </div>

                            <div className="mt-4 mb-4">
                                <FileIcon ischecked={isSelected} size={48} isFolder={item.isFolder} title={item.title} />
                            </div>
                            <div title={item.name} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: 12, width: 82, userSelect: 'none', color: isSelected ? '#fff' : '#000' }}>{item.name}</div>
                        </div>

                    </List.Item>
                )
            }}
        />
    )
}
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Badge, Tooltip } from "antd";
import { store } from "@redux/store";
import { api } from "@redux/api";
import { useSelector } from "react-redux";
import { WorkerCommentTooltip } from "@containers/facility/Workorder/WorkorderTooltip";
import { EventName, useEventBus } from "@/hooks/useEventBus";

const WorkorderNotification = React.memo(({ record }) => {

  const [notifications, setNotifications] = useState({
    unread: record.unread_notifications_count || 0,
    read: record.read_notifications_count || 0,
    initialized: false,
  });

  const drawer = useSelector((state) => state.EditableTable.drawer);
  const drawer_id = useSelector((state) => state.EditableTable.id);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEventBus(EventName.RECIVED_WORKORDER_MESSAGE, (data) => {
    if (record.id === data.parent_id) {
      if (data.type === 'kpiworkordercomment_created') {
        if (!open) {
          setNotifications((prev) => ({
            unread: prev.unread + 1,
            read: prev.read,
          }));
        }
      }
    }
  });

  const setReadComment = useCallback(() => {
    let isMounted = true;
    api
      .updateWorkorderComment(record.id, { status: "read" })
      .then(() => {
        if (isMounted) {
          setNotifications((prev) => ({
            unread: 0,
            read: prev.read + prev.unread,
          }));
        }
      })
      .catch((error) =>
        console.error("Error updating notification status:", error)
      );
    return () => {
      isMounted = false;
    };
  }, [record.id]);

  useEffect(() => {
    if (record.id === drawer_id && drawer === true) {
      const cleanup = setReadComment();
      return cleanup;
    }
  }, [drawer, drawer_id, record.id, setReadComment]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      try {
        const response = await api.getWorkorderComment(record.id);
        if (isSubscribed) {
          setNotifications((prev) => ({
            unread:
              response.data.unread_notifications_count ??
              record.unread_notifications_count ??
              0,
            read:
              response.data.read_notifications_count ??
              record.read_notifications_count ??
              0,
            initialized: true,
          }));
        }
      } catch (error) {
        console.error("Error fetching workorder comment:", error);
        if (isSubscribed) {
          setNotifications((prev) => ({
            unread: record.unread_notifications_count || 0,
            read: record.read_notifications_count || 0,
            initialized: true,
          }));
        }
      } finally {

      }
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, [
    record.id,
    record.unread_notifications_count,
    record.read_notifications_count,
  ]);

  const handleTooltipOpenChange = useCallback(
    (open) => {
      setOpen(open)
      if (open) {
        setReadComment();
      }
    },
    [setReadComment]
  );

  const totalNotifications = notifications.unread + notifications.read;

  return (
    <Tooltip
      placement="topLeft"
      trigger={["click"]}
      overlayStyle={{
        maxWidth: "800px",
        padding: 0,
        marginLeft: "-20px",
      }}
      overlayInnerStyle={{
        padding: 0,
        borderRadius: "8px",
        overflow: "hidden",
      }}
      onOpenChange={handleTooltipOpenChange}
      title={WorkerCommentTooltip(record.id)}
      color="#fff"
      destroyTooltipOnHide={true}
    >
      {
        (totalNotifications > 0 ? (
          <Badge
            count={notifications.unread}
            size="small"
            style={{ cursor: "pointer" }}
          >
            <i
              className="fas fa-comment-dots"
              style={{
                color: "#faad14",
                fontSize: "18px",
                cursor: "pointer",
              }}
            />
          </Badge>
        ) : (
          <Badge size="small">
            <i
              className="fa-regular fa-comment-dots"
              style={{
                color: "grey",
                fontSize: "18px",
                cursor: "pointer",
              }}
            />
          </Badge>
        ))}
    </Tooltip>
  );
});

export default WorkorderNotification;

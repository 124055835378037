import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import { jsforceResponse } from "@/config/httpService";
import { CaretDownOutlined } from "@ant-design/icons";
import { api } from "@redux/api";

import styled from "styled-components";
import { Button } from "antd";

export const ScenarioLookupWrapper = styled.div`
  //.ant-input-affix-wrapper {
  //  border-radius: 1.5rem;
  //  border: 1.9px solid #1890ff !important;
  //  overflow: hidden;
  //}
`;

class ScenarioLookup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selected: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log("SelectedScenario", this.props.SelectedScenario);
  }

  handleEdit = (data) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("handleEdit", data);
    this.props.handleEdit(data);
  };

  handleDelete = (id) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("handleDelete", id);
    this.props.handleDelete(id);
  };

  handleFocus = async (e) => {
    const result1 = await api.filterScenario("");
    const res1 = jsforceResponse(result1, "", false, true);
    let data = _.get(res1, "data.results", []);
    let options = [];
    for (let i = 0; i < data.length; i++) {
      options.push({
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {data[i].name}
            <span>
              <a onClick={this.handleEdit(data[i])}>
                <i className="fas fa-edit text-gray-500 ml-10 mr-10 text-3xl"></i>
              </a>
              <a onClick={this.handleDelete(data[i].id)}>
                <i className="fas fa-trash text-gray-500 text-3xl"></i>
              </a>
            </span>
          </div>
        ),
        value: data[i].name,
        id: data[i].id,
        record: data[i],
      });
    }
    this.setState({
      options,
    });
  };

  handleSearch = async (e) => {
    let query = "issue=" + e;
    const result1 = await api.filterScenario(query);
    const res1 = jsforceResponse(result1, "", false, true);
    let data = _.get(res1, "data.results", []);
    let options = [];
    for (let i = 0; i < data.length; i++) {
      options.push({
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {data[i].name}
            <span>
              <a onClick={this.handleEdit(data[i])}>
                <i className="fas fa-edit text-gray-500 ml-10 mr-10 text-3xl"></i>
              </a>
              <a onClick={this.handleDelete(data[i].id)}>
                <i className="fas fa-trash text-gray-500 text-3xl"></i>
              </a>
            </span>
          </div>
        ),
        value: data[i].name,
        id: data[i].id,
        record: data[i],
      });
    }
    this.setState({
      options,
    });
  };

  handleSelect = (e, record) => {
    console.log("handleSelect", e, record);

    this.setState({
      selected: record.record,
      Scenario: e,
    });

    this.props.SelectedScenario(record);
  };

  openScenario = (e, isNewScenario) => {
    this.setState({
      visible: true,
      isNewScenario,
    });
    this.props.handleOpenScenario(true, isNewScenario);
  };

  render() {
    return (
      <ScenarioLookupWrapper>
        <AutoComplete
          style={{ width: "250px" }}
          options={this.state.options}
          onFocus={this.handleFocus}
          onSelect={this.handleSelect}
          onSearch={this.handleSearch}
          value={this.props.value}
          dropdownMatchSelectWidth={false}
          notFoundContent={() => {
            return <div>No Scenario Found</div>;
          }}
          dropdownRender={(menu) => (
            <div className={""}>
              {menu}
              <div
                style={{ cursor: "pointer" }}
                className={"flex justify-center items-center "}
              >
                <Button
                  type={"primary"}
                  className={"flex justify-center items-center my-2"}
                  onClick={(e) => this.openScenario(e, true)}
                  shape={"circle"}
                  icon={<i className="fas fa-plus"></i>}
                ></Button>
              </div>
            </div>
          )}
        >
          <Input
            size="medium"
            autosize={true}
            allowClear
            placeholder="Select a Scenario"
            autoComplete="off"
            prefix={
              <div style={{ color: "orange" }}>
                <i className="fas fa-arrow-alt-circle-right"></i>
              </div>
            }
            suffix={<CaretDownOutlined />}
          />
        </AutoComplete>
      </ScenarioLookupWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioLookup);

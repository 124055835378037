import React, { Component } from "react";
import SelectAddNew from "@components/SelectAddNew";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Popover,
  Row,
  Segmented,
  Select,
  Space,
  Tooltip,
} from "antd";
import moment from "moment";
import { api } from "@redux/api";
import _ from "lodash";
import { CopyOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Input from "antd/es/input";
import { ScenarioModal } from "@containers/UtilityData/Analysis/ScenarioModal";
import ScenarioFilter from "@containers/UtilityData/Analysis/ScenarioFilter";
import notification2 from "@components/Notification2";
import { connect } from "react-redux";
import styled from "styled-components";

export const CopyOutlinedStyle = styled(CopyOutlined)`
  font-size: 20px;
  cursor: pointer;
  &:hover {
    color: #6a74a5;
  }
`;

const dateValuesFormatMoment = (values) => {
  return values.map((v) => (v ? moment(v) : v));
};

class AnalysisFilters extends Component {
  constructor(props) {
    super(props);

    this.Chart1 = React.createRef();

    this.state = {
      Start_Date: [moment().subtract(12, "months"), moment()],
      scenario_name: null,

      gas_base: [],
      gas_post: [],

      gas_Baseline_Date: [null, null],
      gas_Post_Date: [null, null],
      gas_Price: null,
      gas_Option: "Baseline Average",
      gas_range0: null,
      gas_range1: null,
      gas_breakpoint0: null,
      gas_breakpoint1: null,
      gas_breakpoint2: null,
      gas_breakpoint3: null,
      gas_n_segments: 2,
      gas_usedModuleValue: "Segments",

      elec_base: [],
      elec_post: [],

      elec_Baseline_Date: [null, null],
      elec_Post_Date: [null, null],
      elec_Price: null,
      elec_Option: "Baseline Average",
      elec_range0: null,
      elec_range1: null,
      elec_breakpoint0: null,
      elec_breakpoint1: null,
      elec_breakpoint2: null,
      elec_breakpoint3: null,
      elec_n_segments: 2,
      elec_usedModuleValue: "Segments",

      selectedScenarioId: null,
      selectedScenario: null,
      newScenario: {},
      isNewScenario: false,
      selecteProjectParamsId: null,
      project_name: null,
      is_copied: false,

      elec_Custom_Price: null,
      gas_Custom_Price: null,
      gas_use_latest_month: null,
      elec_use_latest_month: null,
    };
  }
  componentDidMount() {
    const daterange = this.props.daterange;
    const gas_Baseline_Date = [
      moment(daterange["date_range_gas_max"]).add(-23, "month"),
      moment(daterange["date_range_gas_max"]).add(-12, "month"),
    ],
      gas_Post_Date = [
        moment(daterange["date_range_gas_max"]).add(-11, "month"),
        moment(daterange["date_range_gas_max"]),
      ],
      elec_Baseline_Date = [
        moment(daterange["date_range_elec_max"]).add(-23, "month"),
        moment(daterange["date_range_elec_max"]).add(-12, "month"),
      ],
      elec_Post_Date = [
        moment(daterange["date_range_elec_max"]).add(-11, "month"),
        moment(daterange["date_range_elec_max"]),
      ];

    this.setState(
      {
        gas_Baseline_Date,
        gas_Post_Date,
        elec_Baseline_Date,
        elec_Post_Date,
      },
      function () {
        this.handleRender();
      }
    );
  }

  handleRender = () => {
    let elec_base_query = null;
    let elec_post_query = null;
    if (
      moment(this.state.elec_Baseline_Date[0]).format("YYYY-MM-") !==
      "Invalid date"
    ) {
      elec_base_query =
        "start_month=" +
        moment(this.state.elec_Baseline_Date[0]).format("YYYY-MM-") +
        "01" +
        "&end_month=" +
        moment(this.state.elec_Baseline_Date[1])
          .endOf("month")
          .format("YYYY-MM-DD") + '&page_size=99999';
      elec_post_query =
        "start_month=" +
        moment(this.state.elec_Post_Date[0]).format("YYYY-MM-") +
        "01" +
        "&end_month=" +
        moment(this.state.elec_Post_Date[1])
          .endOf("month")
          .format("YYYY-MM-DD")  + '&page_size=99999';
    }

    let gas_base_query = null;
    let gas_post_query = null;
    if (
      moment(this.state.gas_Baseline_Date[0]).format("YYYY-MM-") !==
      "Invalid date"
    ) {
      gas_base_query =
        "start_month=" +
        moment(this.state.gas_Baseline_Date[0]).format("YYYY-MM-") +
        "01" +
        "&end_month=" +
        moment(this.state.gas_Baseline_Date[1])
          .endOf("month")
          .format("YYYY-MM-DD")  + '&page_size=99999';
      gas_post_query =
        "start_month=" +
        moment(this.state.gas_Post_Date[0]).format("YYYY-MM-") +
        "01" +
        "&end_month=" +
        moment(this.state.gas_Post_Date[1])
          .endOf("month")
          .format("YYYY-MM-DD")  + '&page_size=99999';
    }

    Promise.all([
      api.filterElectricity(elec_base_query),
      api.filterElectricity(elec_post_query),
      api.filterGas(gas_base_query),
      api.filterGas(gas_post_query),
    ])
      .then((response) => {
        return {
          elec_base: _.orderBy(
            _.get(response[0], "data.results"),
            "service_month",
            "asc"
          ),
          elec_post: _.orderBy(
            _.get(response[1], "data.results"),
            "service_month",
            "asc"
          ),
          gas_base: _.orderBy(
            _.get(response[2], "data.results"),
            "service_month",
            "asc"
          ),
          gas_post: _.orderBy(
            _.get(response[3], "data.results"),
            "service_month",
            "asc"
          ),
        };
      })
      .then((data) => {
        this.setState(
          {
            elec_base: data.elec_base,
            elec_post: data.elec_post,
            gas_base: data.gas_base,
            gas_post: data.gas_post,
          },
          function () {
            let elec_Price_Type = this.handlePriceTypeCal(
              this.state.elec_Option,
              this.state.elec_Custom_Price,
              data.elec_base,
              data.elec_post
            );
            const elec_Price = elec_Price_Type.average_price;
            let gas_Price_Type = this.handlePriceTypeCal(
              this.state.gas_Option,
              this.state.gas_Custom_Price,
              data.gas_base,
              data.gas_post
            );
            const gas_Price = gas_Price_Type.average_price;
            this.props.onRender(
              this.state.gas_base,
              this.state.gas_post,
              this.state.gas_Baseline_Date,
              this.state.gas_Post_Date,
              gas_Price,
              this.state.gas_range0,
              this.state.gas_range1,
              this.state.gas_breakpoint0,
              this.state.gas_breakpoint1,
              this.state.gas_breakpoint2,
              this.state.gas_breakpoint3,
              this.state.gas_n_segments,

              this.state.elec_base,
              this.state.elec_post,
              this.state.elec_Baseline_Date,
              this.state.elec_Post_Date,
              elec_Price,
              this.state.elec_range0,
              this.state.elec_range1,
              this.state.elec_breakpoint0,
              this.state.elec_breakpoint1,
              this.state.elec_breakpoint2,
              this.state.elec_breakpoint3,
              this.state.elec_n_segments
            );

            this.setState({
              elec_Price,
              gas_Price,
            });
          }
        );
      })
      .catch((error) => error);
  };

  onDateChange = (dates, key) => {
    this.setState(
      {
        [key]: Array.isArray(dates)
          ? dates.map((i) => i.format("YYYY-MM"))
          : [],
      },
      () => {
        if (this.state.is_copied) {
          this.setState({
            gas_Baseline_Date: [...this.state.elec_Baseline_Date],
            gas_Post_Date: [...this.state.elec_Post_Date],
          });
        }
      }
    );
  };

  disabledBaseMinMaxDate = (current, index, type) => {
    let min_date = moment.min(
      moment(_.get(this.props.daterange, "date_range_elec_min")),
      moment(_.get(this.props.daterange, "date_range_gas_min"))
    );
    let max_date = moment.max(
      moment(_.get(this.props.daterange, "date_range_elec_max")),
      moment(_.get(this.props.daterange, "date_range_gas_max"))
    );

    let tooLate;
    if (index === 0) {
      tooLate =
        moment(min_date).diff(moment(current)) >= 0 ||
        moment(current).diff(max_date) >= 0 ||
        moment(current).diff(
          moment(_.get(this.state, type + "Baseline_Date[1]"))
        ) >= 0;
    } else {
      tooLate =
        moment(min_date).diff(moment(current)) >= 0 ||
        moment(current).diff(max_date) >= 0 ||
        moment(moment(_.get(this.state, type + "Baseline_Date[0]"))).diff(
          moment(current)
        ) >= 0;
    }

    return tooLate;
  };

  disabledPostMinMaxDate = (current, index, type) => {
    console.log("disabledPostMinMaxDate", current, index, this.props.daterange);
    let min_date = moment.min(
      moment(_.get(this.props.daterange, "date_range_elec_min")),
      moment(_.get(this.props.daterange, "date_range_gas_min"))
    );
    let max_date = moment.max(
      moment(_.get(this.props.daterange, "date_range_elec_max")),
      moment(_.get(this.props.daterange, "date_range_gas_max"))
    );

    let tooLate;
    if (index === 0) {
      tooLate =
        moment(min_date).diff(moment(current)) >= 0 ||
        moment(current).diff(max_date) >= 0 ||
        moment(current).diff(
          moment(_.get(this.state, type + "Post_Date[1]"))
        ) >= 0;
    } else {
      console.log(
        "tooLate",
        moment(min_date).diff(moment(current)),
        moment(current).diff(max_date),
        _.get(this.state, "Post_Date[0]")
      );
      tooLate =
        moment(min_date).diff(moment(current)) >= 0 ||
        moment(current).diff(max_date) >= 0 ||
        moment(moment(_.get(this.state, type + "Post_Date[0]"))).diff(
          moment(current)
        ) >= 0;
    }

    return tooLate;
  };

  disabledStartMinMaxDate = (current, index) => {
    console.log("disabledStartMinMaxDate");
    let tooLate = false;
    if (index !== 0) {
      let min_date = this.state.Start_Date[0];
      tooLate = moment(min_date).diff(current) > 0;
    }

    return tooLate;
  };

  handlePriceSelect = (price_type, utility_type, custom_price) => {
    let base_data_elec = [],
      post_data_elec = [],
      base_data_gas = [],
      post_data_gas = [];

    if (utility_type === "Gas") {
      base_data_gas = this.state.gas_base;
      post_data_gas = this.state.gas_post;
      const { price_type: price_type_gas, average_price: average_price_gas } =
        this.handlePriceTypeCal(
          price_type,
          custom_price,
          base_data_elec,
          post_data_elec
        );

      this.setState({
        gas_Price: average_price_gas,
        gas_Option: price_type_gas,
        gas_Custom_Price: custom_price,
      });
    }
    if (utility_type === "Elec") {
      base_data_elec = this.state.elec_base;
      post_data_elec = this.state.elec_post;
      const { price_type: price_type_elec, average_price: average_price_elec } =
        this.handlePriceTypeCal(
          price_type,
          custom_price,
          base_data_elec,
          post_data_elec
        );

      this.setState({
        elec_Price: average_price_elec,
        elec_Option: price_type_elec,
        elec_Custom_Price: custom_price,
      });
    }
  };

  handlePriceTypeCal = (price_type, custom_price, baseline_data, post_data) => {
    let average_price = 0;
    let sum_cost = 0;
    let sum_usage = 0;
    if (price_type === "Baseline Average") {
      baseline_data.forEach((e) => {
        sum_cost += e["costs"] * 1;
        sum_usage += e["usage"] * 1;
      });
      average_price = sum_cost / sum_usage;
    } else if (price_type === "Post-Period Average") {
      post_data.forEach((e) => {
        sum_cost += e["costs"] * 1;
        sum_usage += e["usage"] * 1;
      });
      average_price = sum_cost / sum_usage;
    } else {
      average_price = parseFloat(custom_price);
    }
    console.log("price_type, average_price: ", price_type, average_price);
    return { price_type, average_price };
  };

  onGasBreakpointChange = (e, index) => {
    const Breakpoint = [
      "gas_breakpoint0",
      "gas_breakpoint1",
      "gas_breakpoint2",
      "gas_breakpoint3",
    ];
    this.setState({
      [Breakpoint[index]]: e.target.value * 1,
    });
  };

  onElecBreakpointChange = (e, index) => {
    const Breakpoint = [
      "elec_breakpoint0",
      "elec_breakpoint1",
      "elec_breakpoint2",
      "elec_breakpoint3",
    ];
    this.setState({
      [Breakpoint[index]]: e.target.value * 1,
    });
  };

  onNSegmentGasChange = (e) => {
    this.setState({
      gas_n_segments: e,
      gas_breakpoint0: null,
      gas_breakpoint1: null,
      gas_breakpoint2: null,
      gas_breakpoint3: null,
    });
  };

  onNSegmentElecChange = (e) => {
    this.setState({
      elec_n_segments: e,
      elec_breakpoint0: null,
      elec_breakpoint1: null,
      elec_breakpoint2: null,
      elec_breakpoint3: null,
    });
  };

  onGasBreakpointSegmentChange = (e) => {
    // console.log('onBreakpointSegmentChange', e)
    if (e === "Segments") {
      this.setState({
        gas_usedModuleValue: e,
        gas_breakpoint0: null,
        gas_breakpoint1: null,
        gas_breakpoint2: null,
        gas_breakpoint3: null,
      });
    } else {
      this.setState({
        gas_usedModuleValue: e,
        gas_n_segments: 2,
      });
    }
  };

  onElecBreakpointSegmentChange = (e) => {
    // console.log('onBreakpointSegmentChange', e)
    if (e === "Segments") {
      this.setState({
        elec_usedModuleValue: e,
        elec_breakpoint0: null,
        elec_breakpoint1: null,
        elec_breakpoint2: null,
        elec_breakpoint3: null,
      });
    } else {
      this.setState({
        elec_usedModuleValue: e,
        elec_n_segments: 2,
      });
    }
  };

  onCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOpenScenario = (visible, isNewScenario, newState) => {
    this.setState({
      visible: true,
      isNewScenario: isNewScenario,
      ...newState,
    });
  };

  handleUpdateState = (newState) => {
    this.setState(newState);
  };

  onScenarioSubmit = (e) => {
    console.log("submit", e);
    if (this.state.isNewScenario) {
      api
        .createScenario(e)
        .then((response) => {
          notification2["success"]({
            message: "Success",
            description: "Scenario was successfully saved",
            placement: "topLeft",
          });
          this.setState({
            visible: false,
          });
        })
        .catch((err) => {
          notification2["error"]({
            message: "Error",
            description: err.message,
            placement: "topLeft",
          });
        });
    } else {
      api
        .editScenario(e.id, e)
        .then((response) => {
          notification2["success"]({
            message: "Success",
            description: "Scenario was successfully saved",
            placement: "topLeft",
          });
          this.setState({
            visible: false,
          });
        })
        .catch((err) => {
          notification2["error"]({
            message: "Error",
            description: err.message,
            placement: "topLeft",
          });
        });
    }
  };

  handleCheckboxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        gas_Baseline_Date: [...this.state.elec_Baseline_Date],
        gas_Post_Date: [...this.state.elec_Post_Date],
        is_copied: true,
      });
    } else {
      this.setState({
        is_copied: false,
      });
    }
  };

  copySavingAnalysisElecToGas = () => {
    this.setState((state) => ({
      gas_usedModuleValue: state.elec_usedModuleValue,
      gas_breakpoint0: state.elec_breakpoint0,
      gas_breakpoint1: state.elec_breakpoint1,
      gas_breakpoint2: state.elec_breakpoint2,
      gas_breakpoint3: state.elec_breakpoint3,
      gas_Option: state.elec_Option,
      gas_Custom_Price: state.elec_Custom_Price,
      gas_Baseline_Date: state.elec_Baseline_Date,
      gas_Post_Date: state.elec_Post_Date,
      gas_n_segments: state.elec_n_segments,
    }));
  };
  render() {
    return (
      <>
        <Row className={"w-full  relative pb-5"}>
          <Col className={"p-6 flex"}>
            <ScenarioFilter
              handleUpdateState={this.handleUpdateState}
              handleOpenScenario={this.handleOpenScenario}
            />
            <Col
              size={30}
              style={{ width: "100%" }}
              className="flex items-center"
            >
              <Button
                key="1"
                type="primary"
                onClick={this.handleRender}
                className={"round-button"}
                style={{ width: "10rem", marginRight: 10 }}
              >
                Re-calculate
              </Button>
              <Tooltip title="Same as Electricity">
                <CopyOutlinedStyle
                  style={{ fontSize: 20 }}
                  onClick={this.copySavingAnalysisElecToGas}
                />
              </Tooltip>
            </Col>
          </Col>
        </Row>

        <Row className={"flex items-end relative "}>
          <Col
            style={{ width: "100%" }}
            className={"w-full flex justify-between"}
          >
            <div className={"flex flex-wrap"}>
              <div className={"w-72"}>
                <div className={""}>
                  Elec Baseline Period
                  <Popover
                    content={
                      <div>
                        {"elec available from " +
                          _.get(this.props.daterange, "date_range_elec_min") +
                          " to " +
                          _.get(this.props.daterange, "date_range_elec_max")}
                      </div>
                    }
                    trigger="hover"
                    className={""}
                  >
                    <InfoCircleOutlined style={{ color: "orange" }} />
                  </Popover>
                </div>
                <DatePicker.RangePicker
                  picker="month"
                  value={dateValuesFormatMoment(
                    this.state.elec_Baseline_Date
                  )}
                  onChange={(d) => this.onDateChange(d, "elec_Baseline_Date")}
                />
              </div>

              <div className={"w-72"}>
                <div className={""}>
                  Electricity Post Period{" "}
                  <Popover
                    content={
                      <div>
                        <div>
                          {"elec available from " +
                            _.get(
                              this.props.daterange,
                              "date_range_elec_min"
                            ) +
                            " to " +
                            _.get(
                              this.props.daterange,
                              "date_range_elec_max"
                            )}
                        </div>
                      </div>
                    }
                    trigger="hover"
                    className={"pl-1"}
                  >
                    <InfoCircleOutlined style={{ color: "orange" }} />
                  </Popover>
                </div>
                <DatePicker.RangePicker
                  picker="month"
                  value={dateValuesFormatMoment(this.state.elec_Post_Date)}
                  onChange={(d) => this.onDateChange(d, "elec_Post_Date")}
                />
              </div>

              <div className={"ml-2"}>
                <div className={""}>Elec Price</div>
                <SelectAddNew
                  placeholder={"select elec price"}
                  default={"Baseline Average"}
                  type="Elec"
                  value={this.state.elec_Option}
                  onSelect={(e, customPrice) =>
                    this.handlePriceSelect(e, "Elec", customPrice)
                  }
                />
              </div>

              <div className={"ml-2"}>
                <div className={""}>How to Break Line</div>
                <div>
                  <Select
                    onChange={this.onElecBreakpointSegmentChange}
                    value={this.state.elec_usedModuleValue}
                    defaultValue={"Segments"}
                  >
                    <Select.Option value="Segments">Segments</Select.Option>
                    <Select.Option value="Breakpoints">
                      Breakpoints
                    </Select.Option>
                  </Select>
                </div>
              </div>
              <div>
                {this.state.elec_usedModuleValue === "Breakpoints" ? (
                  <div className={""}>
                    <div className={"flex"}>
                      Specify Breakpoints
                      <Popover
                        content={
                          <div>
                            transition points between different linear
                            segments, cannot combined with segements
                          </div>
                        }
                        trigger="hover"
                        className={""}
                      >
                        <InfoCircleOutlined style={{ color: "orange" }} />
                      </Popover>
                    </div>

                    <div className={"flex"}>
                      <Space.Compact>
                        <Input
                          type="integer"
                          allowClear
                          style={{ width: "8rem" }}
                          value={this.state.elec_breakpoint1}
                          onChange={(e) => this.onElecBreakpointChange(e, 1)}
                        />
                        <Input
                          type="integer"
                          allowClear
                          style={{ width: "8rem" }}
                          value={this.state.elec_breakpoint2}
                          onChange={(e) => this.onElecBreakpointChange(e, 2)}
                        />
                        <Input
                          type="integer"
                          allowClear
                          style={{ width: "8rem" }}
                          value={this.state.elec_breakpoint3}
                          onChange={(e) => this.onElecBreakpointChange(e, 3)}
                        />
                      </Space.Compact>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {this.state.elec_usedModuleValue === "Segments" ? (
                  <div className={""}>
                    <div className={"font-medium pl-1 flex item-center"}>
                      Segments
                      <Popover
                        content={
                          <div>
                            use segments will ignore breakpoints, cannot
                            combined with breakpoints
                          </div>
                        }
                        trigger="hover"
                      >
                        <InfoCircleOutlined style={{ color: "orange" }} />
                      </Popover>
                    </div>
                    <Segmented
                      defaultValue={2}
                      options={[1, 2, 3]}
                      value={this.state.elec_n_segments}
                      onChange={this.onNSegmentElecChange}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>


            <div className={"flex flex-wrap"}>
              <div className={"w-72"}>
                <div className={""}>
                  Gas Baseline Period
                  <Popover
                    content={
                      <div>
                        {"elec available from " +
                          _.get(this.props.daterange, "date_range_gas_min") +
                          " to " +
                          _.get(this.props.daterange, "date_range_gas_max")}
                      </div>
                    }
                    trigger="hover"
                    className={""}
                  >
                    <InfoCircleOutlined style={{ color: "orange" }} />
                  </Popover>
                </div>
                <DatePicker.RangePicker
                  picker="month"
                  value={dateValuesFormatMoment(this.state.gas_Baseline_Date)}
                  onChange={(d) => this.onDateChange(d, "gas_Baseline_Date")}
                />
              </div>

              <div className={"w-72"}>
                <div className={""}>
                  Electricity Post Period{" "}
                  <Popover
                    content={
                      <div>
                        <div>
                          {"gas available from " +
                            _.get(
                              this.props.daterange,
                              "date_range_gas_min"
                            ) +
                            " to " +
                            _.get(this.props.daterange, "date_range_gas_max")}
                        </div>
                      </div>
                    }
                    trigger="hover"
                    className={"pl-1"}
                  >
                    <InfoCircleOutlined style={{ color: "orange" }} />
                  </Popover>
                </div>
                <DatePicker.RangePicker
                  picker="month"
                  value={dateValuesFormatMoment(this.state.gas_Post_Date)}
                  onChange={(d) => this.onDateChange(d, "gas_Post_Date")}
                />
              </div>

              <div className={"ml-2"}>
                <div className={""}>Gas Price</div>
                <SelectAddNew
                  placeholder={"select gas price"}
                  default={"Baseline Average"}
                  type="Gas"
                  value={this.state.gas_Option}
                  onSelect={(e, customPrice) =>
                    this.handlePriceSelect(e, "Gas", customPrice)
                  }
                />
              </div>

              <div className={"ml-2"}>
                <div className={""}>How to Break Line</div>
                <div>
                  <Select
                    onChange={this.onGasBreakpointSegmentChange}
                    value={this.state.gas_usedModuleValue}
                    defaultValue={"Segments"}
                  >
                    <Select.Option value="Segments">Segments</Select.Option>
                    <Select.Option value="Breakpoints">
                      Breakpoints
                    </Select.Option>
                  </Select>
                </div>
              </div>
              <div>
                {this.state.gas_usedModuleValue === "Breakpoints" ? (
                  <div className={""}>
                    <div className={"flex"}>
                      Specify Breakpoints
                      <Popover
                        content={
                          <div>
                            transition points between different linear
                            segments, cannot combined with segements
                          </div>
                        }
                        trigger="hover"
                        className={""}
                      >
                        <InfoCircleOutlined style={{ color: "orange" }} />
                      </Popover>
                    </div>

                    <div className={"flex"}>
                      <Space.Compact>
                        <Input
                          type="integer"
                          allowClear
                          style={{ width: "8rem" }}
                          value={this.state.gas_breakpoint1}
                          onChange={(e) => this.onGasBreakpointChange(e, 1)}
                        />
                        <Input
                          type="integer"
                          allowClear
                          style={{ width: "8rem" }}
                          value={this.state.gas_breakpoint2}
                          onChange={(e) => this.onGasBreakpointChange(e, 2)}
                        />
                        <Input
                          type="integer"
                          allowClear
                          style={{ width: "8rem" }}
                          value={this.state.gas_breakpoint3}
                          onChange={(e) => this.onGasBreakpointChange(e, 3)}
                        />
                      </Space.Compact>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {this.state.gas_usedModuleValue === "Segments" ? (
                  <div className={""}>
                    <div className={"font-medium pl-1 flex item-center"}>
                      Segments
                      <Popover
                        content={
                          <div>
                            use segments will ignore breakpoints, cannot
                            combined with breakpoints
                          </div>
                        }
                        trigger="hover"
                      >
                        <InfoCircleOutlined style={{ color: "orange" }} />
                      </Popover>
                    </div>
                    <Segmented
                      defaultValue={2}
                      options={[1, 2, 3]}
                      value={this.state.gas_n_segments}
                      onChange={this.onNSegmentGasChange}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

          </Col>
        </Row>

        <ScenarioModal
          isNewScenario={this.state.isNewScenario}
          visible={this.state.visible}
          onCancel={this.onCancel}
          onScenarioSubmit={this.onScenarioSubmit}
          scenario_name={this.state.scenario_name}
          id={this.state.selectedScenarioId}
          gas_base={this.state.gas_base}
          gas_option={this.state.gas_Option}
          gas_post_start_date={
            this.state.gas_Post_Date[0]
              ? moment(this.state.gas_Post_Date[0]).format("YYYY-MM-DD")
              : null
          }
          gas_post_end_date={
            this.state.gas_Post_Date[1]
              ? moment(this.state.gas_Post_Date[1]).format("YYYY-MM-DD")
              : null
          }
          gas_baseline_start_date={
            this.state.gas_Baseline_Date[0]
              ? moment(this.state.gas_Baseline_Date[0]).format("YYYY-MM-DD")
              : null
          }
          gas_baseline_end_date={
            this.state.gas_Baseline_Date[1]
              ? moment(this.state.gas_Baseline_Date[1]).format("YYYY-MM-DD")
              : null
          }
          gas_price={this.state.gas_Custom_Price}
          gas_breakpoint1={this.state.gas_breakpoint1}
          gas_breakpoint2={this.state.gas_breakpoint2}
          gas_breakpoint3={this.state.gas_breakpoint3}
          gas_segment={this.state.gas_n_segments}
          gas_use_breakpoints={this.state.gas_usedModuleValue}
          gas_range1={this.state.gas_range1}
          gas_range0={this.state.gas_range0}
          elec_base={this.state.elec_base}
          elec_option={this.state.elec_Option}
          elec_post_start_date={
            this.state.elec_Post_Date[0]
              ? moment(this.state.elec_Post_Date[0]).format("YYYY-MM-DD")
              : null
          }
          elec_post_end_date={
            this.state.elec_Post_Date[1]
              ? moment(this.state.elec_Post_Date[1]).format("YYYY-MM-DD")
              : null
          }
          elec_baseline_start_date={
            this.state.elec_Baseline_Date[0]
              ? moment(this.state.elec_Baseline_Date[0]).format("YYYY-MM-DD")
              : null
          }
          elec_baseline_end_date={
            this.state.elec_Baseline_Date[1]
              ? moment(this.state.elec_Baseline_Date[1]).format("YYYY-MM-DD")
              : null
          }
          elec_price={this.state.elec_Custom_Price}
          elec_breakpoint1={this.state.elec_breakpoint1}
          elec_breakpoint2={this.state.elec_breakpoint2}
          elec_breakpoint3={this.state.elec_breakpoint3}
          elec_segment={this.state.elec_n_segments}
          elec_use_breakpoints={this.state.elec_usedModuleValue}
          elec_range1={this.state.elec_range1}
          elec_range0={this.state.elec_range0}
          public={this.state.public}
          gas_use_latest_month={this.state.gas_use_latest_month}
          elec_use_latest_month={this.state.elec_use_latest_month}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    daterange: state.GlobalData.dateRange,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisFilters);

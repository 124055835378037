import React, { useState } from "react";
import { Row, Col, Select, DatePicker, Form, Spin } from "antd";
import { NormalizedWeatherCharts } from "./components/NormalizedWeatherCharts";
import { ConsumptionAndCostsCharts } from "./components/ConsumptionAndCostsCharts";
import { KeyStatistics } from "./components/KeyStatistics";
import { GaugeCharts } from "./components/GaugeCharts";
import { PieCharts } from "./components/PieCharts";
import { EUIRolling12MonthTrendChart } from "./components/EUIRolling12MonthTrendChart";
import { EUIRollingCostsCharts } from "./components/EUIRollingCostsCharts";
import moment from "moment";
import useDateRange from "@/hooks/use-date-range";
import { safemomentmin, safemomentmax } from "./utils";
import styled from "styled-components";

const FormLabel = styled.span`
  font-size: 13px;
  font-weight: 500;
  color: #4b5563;
  margin-right: 6px;
`;

const gutter = [12, 12];
const colStyle = {
  xs: 24,
  lg: 12,
  xxl: 8,
  style: {
    marginBottom: 12,
  },
};

const FilterBar = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  padding: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledSelect = styled(Select)`
  &.ant-select {
    min-width: 120px;

    .ant-select-selector {
      border-radius: 6px;
      height: 32px;
      padding: 0 8px;
      border-color: #e5e7eb;

      .ant-select-selection-item {
        line-height: 30px;
        font-weight: 500;
        color: #374151;
      }
    }

    &:hover .ant-select-selector {
      border-color: #d1d5db;
    }
  }
`;

const StyledDatePicker = styled(DatePicker)`
  &.ant-picker {
    border-radius: 6px;
    height: 32px;
    border-color: #e5e7eb;

    &:hover {
      border-color: #d1d5db;
    }

    .ant-picker-input > input {
      font-size: 13px;
      color: #374151;
    }
  }
`;

const ChartCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .chart-title {
    font-size: 13px;
    font-weight: 500;
    color: #1f2937;
    margin-bottom: 12px;
    flex: 0 0 auto;
  }

  .chart-content {
    flex: 1;
    min-height: 0;
    position: relative;
  }
`;

const CircularChartCard = styled(ChartCard)`
  aspect-ratio: 1;
  min-height: unset;

  .chart-content {
    position: absolute;
    top: 16px;
    left: 16px;
    right: 16px;
    bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const PageWrapper = styled.div`
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const NewSummary = () => {
  const dateRange = useDateRange();
  const [period, setPeriod] = useState("12");
  const [endingMonth, setEndingMonth] = useState(() =>
    safemomentmax(
      moment(dateRange.date_range_elec_max),
      moment(dateRange.date_range_gas_max)
    )
  );
  const [rangeMonth, setRangeMonth] = useState(() => {
    const max = safemomentmax(
      moment(dateRange.date_range_elec_max),
      moment(dateRange.date_range_gas_max)
    );
    return [moment().subtract(11, "month"), max];
  });
  const [updateKey, setUpdateKey] = useState(0);

  let resultPeriod = period;
  let resultEndingMonth = moment(endingMonth).format("YYYY-MM");
  let resultDateRange = rangeMonth;

  if (period === "custom") {
    resultPeriod = rangeMonth[1].diff(rangeMonth[0], "month") + 1;
    resultEndingMonth = rangeMonth[1].format("YYYY-MM");
  } else if (period === "YTD") {
    resultPeriod = moment().diff(moment().startOf("year"), "month") + 1;
    resultEndingMonth = moment().format("YYYY-MM");
  } else if (period === "All") {
    const range = [
      safemomentmin(
        moment(dateRange.date_range_elec_min),
        moment(dateRange.date_range_gas_min)
      ),
      safemomentmax(
        moment(dateRange.date_range_elec_max),
        moment(dateRange.date_range_gas_max)
      ),
    ];
    resultPeriod = range[1].diff(range[0], "month") + 1;
    resultEndingMonth = range[1].format("YYYY-MM");
    resultDateRange = range;
  }

  return (
    <PageWrapper>
      <FilterBar>
        <StyledSelect
          allowClear={false}
          value={period}
          onChange={setPeriod}
          placeholder="Select Period"
        >
          <Select.Option value="custom">Custom</Select.Option>
          <Select.Option value="1">1 Month</Select.Option>
          <Select.Option value="3">3 Month</Select.Option>
          <Select.Option value="6">6 Month</Select.Option>
          <Select.Option value="YTD">YTD</Select.Option>
          <Select.Option value="12">1 Year</Select.Option>
          <Select.Option value="24">2 Year</Select.Option>
          <Select.Option value="36">3 Year</Select.Option>
          <Select.Option value="60">5 Year</Select.Option>
          <Select.Option value="All">All</Select.Option>
        </StyledSelect>

        {period === "custom" || period === "All" ? (
          <>
            <FormLabel>Range Month:</FormLabel>
            <StyledDatePicker.RangePicker
              disabledDate={(current) => {
                const max = safemomentmax(
                  moment(dateRange.date_range_elec_max),
                  moment(dateRange.date_range_gas_max)
                );
                const min = safemomentmin(
                  moment(dateRange.date_range_elec_min),
                  moment(dateRange.date_range_gas_min)
                );
                return (
                  current.isAfter(max, "month") ||
                  current.isBefore(min, "month")
                );
              }}
              disabled={period === "All"}
              value={resultDateRange}
              picker="month"
              style={{ minWidth: 260 }}
            />
          </>
        ) : (
          period !== "YTD" && (
            <>
              <FormLabel>Ending Month:</FormLabel>
              <StyledDatePicker
                allowClear={false}
                value={moment(resultEndingMonth)}
                onChange={(value) => {
                  setEndingMonth(value.format("YYYY-MM"));
                }}
                picker="month"
                disabledDate={(current) => {
                  const max = safemomentmax(
                    moment(dateRange.date_range_elec_max),
                    moment(dateRange.date_range_gas_max)
                  );
                  const min = safemomentmin(
                    moment(dateRange.date_range_elec_min),
                    moment(dateRange.date_range_gas_min)
                  );
                  return (
                    current.isAfter(max, "month") ||
                    current.isBefore(min, "month")
                  );
                }}
              />
            </>
          )
        )}
      </FilterBar>
      <SummaryChart
        resultPeriod={resultPeriod}
        resultEndingMonth={resultEndingMonth}
      />
    </PageWrapper>
  );
};

export const SummaryChart = ({
  resultPeriod,
  resultEndingMonth,
  updateKey = 0,
}) => {
  return (
    <div style={{ flex: 1, minHeight: 0, overflow: "auto" }}>
      <Row gutter={gutter} key={updateKey} style={{ margin: 0 }}>
        <Col {...colStyle}>
          <Row gutter={[0, 12]}>
            <Col span={24} style={{ minHeight: 360 }}>
              <ChartCard>
                <div className="chart-title">
                  Monthly Electricity Usage and Costs
                </div>
                <div className="chart-content">
                  <ConsumptionAndCostsCharts
                    type="electricity"
                    period={resultPeriod}
                    endingMonth={resultEndingMonth}
                  />
                </div>
              </ChartCard>
            </Col>
            <Col span={24} style={{ minHeight: 360 }}>
              <ChartCard>
                <div className="chart-content">
                  <ConsumptionAndCostsCharts
                    type="gas"
                    period={resultPeriod}
                    endingMonth={resultEndingMonth}
                  />
                </div>
              </ChartCard>
            </Col>
            <Col span={24} style={{ minHeight: 360 }}>
              <ChartCard>
                <div className="chart-content">
                  <EUIRollingCostsCharts
                    period={resultPeriod}
                    endingMonth={resultEndingMonth}
                  />
                </div>
              </ChartCard>
            </Col>
          </Row>
        </Col>
        <Col {...colStyle}>
          <Row gutter={[0, 12]}>
            <Col span={24} style={{ minHeight: 360 }}>
              <ChartCard>
                <div className="chart-title">Normalized Weather Charts</div>
                <div className="chart-content">
                  <NormalizedWeatherCharts
                    type="electricity"
                    period={resultPeriod}
                    endingMonth={resultEndingMonth}
                  />
                </div>
              </ChartCard>
            </Col>
            <Col span={24} style={{ minHeight: 360 }}>
              <ChartCard>
                <div className="chart-content">
                  <NormalizedWeatherCharts
                    type="gas"
                    period={resultPeriod}
                    endingMonth={resultEndingMonth}
                  />
                </div>
              </ChartCard>
            </Col>
            <Col span={24} style={{ minHeight: 360 }}>
              <ChartCard>
                <div className="chart-content">
                  <EUIRolling12MonthTrendChart
                    period={resultPeriod}
                    endingMonth={resultEndingMonth}
                  />
                </div>
              </ChartCard>
            </Col>
          </Row>
        </Col>
        <Col {...colStyle}>
          <Row gutter={[0, 12]}>
            <Col span={24} style={{ minHeight: 400 }}>
              <ChartCard>
                <KeyStatistics
                  period={resultPeriod}
                  endingMonth={resultEndingMonth}
                />
              </ChartCard>
            </Col>
            <Col span={24}>
              <Row gutter={[0, 0]}>
                <Col span={12} style={{ aspectRatio: "1" }}>
                  <CircularChartCard>
                    <div className="chart-content">
                      <GaugeCharts
                        period={resultPeriod}
                        endingMonth={resultEndingMonth}
                      />
                    </div>
                  </CircularChartCard>
                </Col>
                <Col span={12} style={{ aspectRatio: "1" }}>
                  <CircularChartCard>
                    <div className="chart-content">
                      <PieCharts
                        period={resultPeriod}
                        endingMonth={resultEndingMonth}
                      />
                    </div>
                  </CircularChartCard>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default NewSummary;

import React from "react";
import EChartsReact from "@components/EChartsReact";
import LayoutContent from "@components/utility/layoutContent";
import _ from "lodash";

const colors = {
    Completed: '#4ade80',
    Modified: '#60a5fa',
    Created: '#fb923c',
}
// mock_data
// const data = [
//     { "date_range": "Past 7 Days", "status": "Created", "value": 1 },
//     { "date_range": "Past 7 Days", "status": "Completed", "value": 2 },
//     { "date_range": "Past 7 Days", "status": "Modified", "value": 3 },
//     { "date_range": "Past 30 Days", "status": "Created", "value": 3 },
//     { "date_range": "Past 30 Days", "status": "Completed", "value": 2 },
//     { "date_range": "Past 30 Days", "status": "Modified", "value": 1 },
//     { "date_range": "Past 90 Days", "status": "Created", "value": 1 },
//     { "date_range": "Past 90 Days", "status": "Completed", "value": 1 },
//     { "date_range": "Past 90 Days", "status": "Modified", "value": 1 }
// ]

const ColumnChart = (props) => {
    const {
        data,
        isStack,
        title,
        handleChartClick
    } = props;

    // 获取所有唯一的系列名称
    const xAxisCategories = ['Past 7 Days', 'Past 30 Days', 'Past 90 Days'];
    const seriesData = xAxisCategories.map(dateRange => ({
        Created: data.find(d => d.date_range === dateRange && d.status === 'Created')?.value || 0,
        Completed: data.find(d => d.date_range === dateRange && d.status === 'Completed')?.value || 0,
        Modified: data.find(d => d.date_range === dateRange && d.status === 'Modified')?.value || 0
    }));

    const option = {
        backgroundColor: '#fff',
        title: {
            text: title,
            left: 'center',
            top: '2%',
            textStyle: {
                color: '#396598',
                fontSize: 14
            }
        },
        legend: {
            data: ['Created',  'Modified', 'Completed'],
            left: 'center',
            top: '10%'
        },
        // dataZoom: [
        //     {
        //         type: 'slider',
        //         show: true,
        //         xAxisIndex: [0],
        //         start: 0,
        //         end: 100,
        //         bottom: '2%'
        //     },
        //     {
        //         type: 'inside',
        //         xAxisIndex: [0],
        //         start: 0,
        //         end: 100
        //     }
        // ],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        xAxis: {
            type: 'category',
            data: xAxisCategories,
            axisLabel: {
                interval: 0,
                // rotate: 30
            }
        },
        yAxis: {
            type: 'value'
        },
        series: ['Created', 'Modified', 'Completed',].map((status) => ({
            name: status,
            type: 'bar',
            barWidth: '14%', 
            stack: isStack ? 'total' : undefined,
            data: seriesData.map(d => d[status]),
            itemStyle: {
                color: colors?.[status] || undefined,
                borderRadius: [6, 6, 0, 0],
            }
        })),
        grid: {
            top: '20%',
            left: '3%',
            right: '4%',
            bottom: '6%',
            containLabel: true
        }
    };

    const onEvents = {
        'click': (params) => {
            const clickedData = seriesData.find((item, index) =>
                xAxisCategories[index] === params.name &&
                ['Created',  'Modified', 'Completed',].includes(params.seriesName)
            );
            if (clickedData && handleChartClick) {
                handleChartClick({
                    date_range: params.name,
                    status: params.seriesName
                });
            }
        }
    };

    return (
        <EChartsReact
            option={option}
            style={{ height: "100%", width: "100%" }}
            onEvents={onEvents}
        />
    );
};

export default ColumnChart;

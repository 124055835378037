import React, { Component, PureComponent, Suspense } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Spin, Table } from "antd";

import moment from "moment";
import { api } from "@redux/api";
import _ from "lodash";
import { Spinner } from "@components/Spinner";
import { CurrencyFormat, NoDecimalFormat, PercentFormat, TwoDecimalFormat } from "@components/Format";
import AnalysisFilter from "@containers/UtilityData/Analysis/AnalysisFilter";
import TotalCostBreakdownChart from "@containers/UtilityData/Summary/Charts/TotalCostBreakdownChart";
import {
    NormalizedCalculationsElec,
    NormalizedCalculationsGas,
    StandardizedCalculationsElec,
    StandardizedCalculationsGas
} from "./NormalizedCalculations";
import { AnalysisTableWrapper } from "@containers/UtilityData/Analysis/Analysis.styles";
import { SavingCharts } from './New-Savings/SavingCharts';

const PeridCardIdMappings = {
    ElectricityNormalized: {
        id: 'ChartId_ElectricityNormalized',
        title: 'Electricity Normalized Consumption Vs. Weather'
    },
    GasNormalized: {
        id: 'ChartId_GasNormalized',
        title: 'Gas Normalized Consumption Vs. Weather'
    },
    MonthlyElectricity: {
        id: 'ChartId_MonthlyElectricity',
        title: 'Electricity Monthly Consumption and Costs'
    },
    MonthlyGas: {
        id: 'ChartId_MonthlyGas',
        title: 'Monthly Gas Consumption and Costs'
    },
    MonthlyElectricityPrices: {
        id: 'ChartId_MonthlyElectricityPrices',
        title: 'Monthly Electricity Prices'
    },
    MonthlyGasPrices: {
        id: 'ChartId_MonthlyGasPrices',
        title: 'Monthly Gas Prices'
    },
    MonthlyEUI: {
        id: 'ChartId_MonthlyEUI',
        title: 'Monthly EUI (kBtu/sqft-yr)'
    },
}


const columns = [
    {
        title: '',
        children: [
            {
                title: 'id',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Service Start',
                dataIndex: 'ServiceStart',
                key: 'ServiceStart',
            },
            {
                title: 'Service End',
                dataIndex: 'ServiceEnd',
                key: 'ServiceEnd',
            },
            {
                title: 'Usage',
                dataIndex: 'Usage',
                key: 'Usage',
            },
            {
                title: 'Adjustment',
                dataIndex: 'Adjustment',
                key: 'Adjustment',
            },
            {
                title: 'Service Month',
                dataIndex: 'ServiceMonth',
                key: 'ServiceMonth',
            },
            {
                title: 'Avg OAT',
                dataIndex: 'AvgOAT',
                key: 'AvgOAT',
                render: (text, record) => {
                    return TwoDecimalFormat(text)
                }
            },
            {
                title: 'Area',
                dataIndex: 'Area',
                key: 'Area',
            }
        ]
    },
    {
        title: '',
        children: [
            {
                title: 'Service Days',
                dataIndex: 'ServiceDays',
                key: 'ServiceDays',
            },
            {
                title: 'Adjust Usage',
                dataIndex: 'AdjustUsage',
                key: 'AdjustUsage',
            },
            {
                title: 'Norm Usage',
                dataIndex: 'NormUsage',
                key: 'NormUsage'
            }]
    },
    {
        title: '',
        children: [
            {
                title: 'Model Usage',
                dataIndex: 'ModelUsage',
                key: 'ModelUsage'
            },
            {
                title: 'Base Usage',
                dataIndex: 'BasicUsage',
                key: 'BasicUsage'
            },
            {
                title: 'Saved Usage',
                dataIndex: 'SavedUsage',
                key: 'SavedUsage'
            },
            {
                title: 'Savings',
                dataIndex: 'Savings',
                key: 'Savings'
            },
            {
                title: 'Cost Savings',
                dataIndex: 'CostSavings',
                key: 'CostSavings'
            },
            {
                title: 'Cumulative Savings',
                dataIndex: 'CumulativeSavings',
                key: 'CumulativeSavings'
            }]
    }
];

class SavingsPage extends PureComponent {

    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.Chart1 = React.createRef();

        this.state = {
            data: [],
            filter_dates: [null, null],

            dataSource: [],
            eui_data: [],
            total_cost_breakdown: [],
            annual_eui: 0,

            ElecData: [],
            ElecReg: [],
            ElecUsage: [],
            ElecCumulativeUsage: [],
            ElecMonthlyUsage: [],
            ElecR_Squared: null,
            ElecCv: null,
            ElecPostDate: [],
            ElecSelected: [],
            ElecSummaryData: [],
            ElecTotals: [],

            GasData: [],
            GasReg: [],
            GasUsage: [],
            GasCumulativeUsage: [],
            GasMonthlyUsage: [],
            GasR_Squared: null,
            GasCv: null,
            GasPostDate: [],
            GasSelected: [],
            GasSummaryData: [],
            GasTotals: [],

            isOpen: true,
            isLoading: false,
        }
    }

    initState = (type_key) => {
        this.setState({
            [`${type_key}Data`]: [],
            [`${type_key}Reg`]: [],
            [`${type_key}Usage`]: [],
            [`${type_key}CumulativeUsage`]: [],
            [`${type_key}MonthlyUsage`]: [],
            [`${type_key}R_Squared`]: null,
            [`${type_key}Cv`]: null,
            [`${type_key}PostDate`]: [],
            [`${type_key}Selected`]: [],
            [`${type_key}SummaryData`]: [],
            [`${type_key}Totals`]: [],
        })
    }


    handleRender = (
        gas_base,
        gas_post,
        gas_Baseline_Date,
        gas_Post_Date,
        gas_Price,
        gas_range0,
        gas_range1,
        gas_breakpoint0,
        gas_breakpoint1,
        gas_breakpoint2,
        gas_breakpoint3,
        gas_n_segments,
        elec_base,
        elec_post,
        elec_Baseline_Date,
        elec_Post_Date,
        elec_Price,
        elec_range0,
        elec_range1,
        elec_breakpoint0,
        elec_breakpoint1,
        elec_breakpoint2,
        elec_breakpoint3,
        elec_n_segments
    ) => {
        this.Init(this.props, elec_Baseline_Date, elec_Post_Date, elec_base,
            elec_post, elec_Price, 'Electricity', 'Elec',
            elec_range0, elec_range1, elec_breakpoint0, elec_breakpoint1, elec_breakpoint2, elec_breakpoint3, elec_n_segments);
        this.Init(this.props, gas_Baseline_Date, gas_Post_Date, gas_base,
            gas_post, gas_Price, 'Gas', 'Gas',
            gas_range0, gas_range1, gas_breakpoint0, gas_breakpoint1, gas_breakpoint2, gas_breakpoint3, gas_n_segments);
    }

    Init = async (props, Baseline_Date, Post_Date, dataset_baseline, dataset_post, price, type, type_key,
        range0, range1, breakpoint0, breakpoint1, breakpoint2, breakpoint3, n_segments) => {

        let usage = [], dates = [];

        var x_baseline = dataset_baseline.map(function (d) {
            return parseFloat(d['avgoat']);
        });

        var x_post = dataset_post.map(function (d) {
            return parseFloat(d['avgoat']);
        });

        //construct y axis
        var y_baseline = dataset_baseline.map(function (d) {
            let adj = d['usage'] * 1 + d['adjustment'] * 1;
            let dateDiff = d['service_date']

            let normal =
                (type === 'Gas' ? NormalizedCalculationsGas(adj, dateDiff, props.Area) :
                    NormalizedCalculationsElec(adj, dateDiff, props.Area));
            return normal
        });

        var y_post = dataset_post.map(function (d) {
            let adj = d['usage'] * 1 + d['adjustment'] * 1;
            let dateDiff = d['service_date']

            let normal = (type === 'Gas' ? NormalizedCalculationsGas(adj, dateDiff, props.Area) :
                NormalizedCalculationsElec(adj, dateDiff, props.Area));
            usage.push({
                id: d['id'],
                service_start_date: d['service_start_date'],
                end_start_date: d['end_start_date'],
                service_month: d['service_month'],
                Usage: adj,
                Area: props.Area,
                Adjustment: d['adjustment'],
                AvgOAT: d['avgoat'],
                service_date: d['service_date']
            })
            dates.push(moment(d['service_month']).format('YYYY-MM'))
            return normal
        });
        let hasHandledData = false
        if (x_baseline.length > 0) {
            await api.linearreg(x_baseline, y_baseline, x_post, y_post,
                range0, range1, breakpoint0, breakpoint1, breakpoint2, breakpoint3, n_segments).then(
                    response => {
                        let dataSource = response.data;

                        return {
                            baseline_result: {
                                data: dataSource,
                                success: true,
                            },
                            post_result: {
                                data: {
                                    x: x_post,
                                    y: y_post,
                                    yPredicted: dataSource.yPredicted
                                },
                                success: true,
                            }
                        }
                    }
                ).then((data) => {
                    hasHandledData = true
                    this.handleData(data, dataset_baseline, dataset_post, usage, type, price, type_key, Post_Date)
                }
                ).catch(error => (
                    error
                ))
        }
        if (!hasHandledData) {
            this.initState(type_key)
        }

    }

    handleData = (combined_result, dataset_baseline, dataset_post, usage, type, price, type_key, Post_Date) => {
        let summary_data = []
        let baseline_result = _.get(combined_result, 'baseline_result');
        let post_result = _.get(combined_result, 'post_result');

        let lr_baseline = _.get(baseline_result, 'data');
        let lr_post = _.get(post_result, 'data');
        let lr_data = [];
        for (let i = 0; i < lr_baseline.x.length; i++) {
            lr_data.push({
                x: lr_baseline.x[i],
                y: lr_baseline.y[i],
                type: 'Baseline',
                date: dataset_baseline[i]['service_month'].substring(0, 7)
            })
        }
        for (let i = 0; i < lr_post.x.length; i++) {
            lr_data.push({
                x: lr_post.x[i],
                y: lr_post.y[i],
                type: 'Post Period',
                date: dataset_post[i]['service_month'].substring(0, 7)
            })
        }

        let regression_data = [];
        for (let i = 0; i < lr_baseline.xHat.length; i++) {
            regression_data.push([lr_baseline.xHat[i], lr_baseline.yHat[i]])
        }

        let usage_data = [];

        usage.map((e, index) => {
            let dateDiff = e['service_date']

            let u = (type === 'Gas' ? StandardizedCalculationsGas(lr_post.yPredicted[index], dateDiff, this.props.Area) :
                StandardizedCalculationsElec(lr_post.yPredicted[index], dateDiff, this.props.Area));
            usage_data.push({
                date: moment(e['service_month']).format('YYYY-MM'),
                value: u,
                type: 'Baseline',
                area: this.props.Area,
                service_date: e['service_date']
            })


        })

        usage.map((e, index) => {
            usage_data.push({
                date: moment(e['service_month']).format('YYYY-MM'),
                value: e['Usage'] + e['Adjustment'],
                type: 'Post Period',
                area: this.props.Area,
                service_date: e['service_date']
            })
        })

        // Cumulative Cost Savings
        let saved_usage = [], saved_sum = [], monthly_saved_usage = [];

        const totals = {
            Usage: 0,
            Adjustment: 0,
            ServiceDays: 0,
            AdjustUsage: 0,
            NormUsage: 0,
            ModelUsage: 0,
            BasicUsage: 0,
            SavedUsage: 0,
            CostSavings: 0,
            CumulativeSavings: 0,
        };

        usage.map((e, index) => {
            let dateDiff = e['service_date']
            // let base = lr_post.yPredicted[index] * this.props.Area * dateDiff / 1000 * (type === 'Electricity' ? 24 : 1);
            let base = (type === 'Gas' ?
                StandardizedCalculationsGas(lr_post.yPredicted[index], dateDiff, this.props.Area) :
                StandardizedCalculationsElec(lr_post.yPredicted[index], dateDiff, this.props.Area));
            let adjust = e['Usage'] + e['Adjustment'];
            let saved = (base - adjust) * price;

            saved_sum.push(saved)
            saved_usage.push({
                date: moment(e['service_month']).format('YYYY-MM'),
                value: saved_sum.reduce((a, b) => a + b, 0),
                type: type
            })

            monthly_saved_usage.push({
                date: moment(e['service_month']).format('YYYY-MM'),
                value: saved?.toFixed(2) * 1,
                type: type
            })

            const NormUsage = (type === 'Gas' ? NormalizedCalculationsGas(adjust, dateDiff, this.props.Area) :
                NormalizedCalculationsElec(adjust, dateDiff, this.props.Area));
            const cumulative_usage = saved_sum.reduce((a, b) => a + b, 0);
            summary_data.push({
                'id': e['id'],
                'ServiceStart': e['service_start_date'],
                'ServiceEnd': e['end_start_date'],
                'Usage': NoDecimalFormat(e['Usage']),
                'Adjustment': e['Adjustment'],
                'ServiceMonth': moment(e['service_month']).format('YYYY-MM'),
                'AvgOAT': TwoDecimalFormat(e['AvgOAT']),
                'Area': NoDecimalFormat(this.props.Area),
                'ServiceDays': dateDiff,
                'AdjustUsage': NoDecimalFormat(adjust),
                // 'NormUsage': TwoDecimalFormat(adjust * 1000 / dateDiff / this.props.Area * (type === 'Electricity' ? 24 : 1)),
                'NormUsage': TwoDecimalFormat(NormUsage),

                'ModelUsage': TwoDecimalFormat(lr_post.yPredicted[index]),

                'BasicUsage': NoDecimalFormat(base),
                'SavedUsage': NoDecimalFormat(base - adjust),
                'Savings': PercentFormat((base - adjust) / base),
                'CostSavings': CurrencyFormat(saved),
                'CumulativeSavings': CurrencyFormat(cumulative_usage)
            })

            totals.Usage += parseFloat(e.Usage) || 0;
            totals.Adjustment += parseFloat(e.Adjustment) || 0;
            totals.ServiceDays += parseFloat(dateDiff) || 0;
            totals.AdjustUsage += parseFloat(adjust) || 0;
            totals.BasicUsage += base;
            totals.SavedUsage += (base - adjust);
            totals.CostSavings += saved;
            totals.CumulativeSavings = cumulative_usage;

        })

        this.setState({
            [type_key + 'Data']: lr_data,
            [type_key + 'Reg']: regression_data,
            [type_key + 'Usage']: usage_data,
            [type_key + 'CumulativeUsage']: saved_usage,
            [type_key + 'R_Squared']: lr_baseline.r_squared,
            [type_key + 'Cv']: lr_baseline.cv,
            [type_key + 'PostDate']: Post_Date,
            [type_key + 'SummaryData']: summary_data,
            [type_key + 'MonthlyUsage']: monthly_saved_usage,
            [type_key + 'Totals']: totals,
            isLoading: false
        })
    }

    handleLGSelect = (e, type) => {
        // console.log('scatterPlot select', e, type)
        if (!_.get(e, 'x')) {
            let x = _.get(e, 'date')
            e['x'] = x
        }
        this.setState({
            [type + 'Selected']: e
        })
    }

    handlePrint = () => {
        this.child.current.composeManagerPDF('Report', 1);
    }

    renderLastMonthEUI = (value, data) => {
        let color = 'orange', title = PeridCardIdMappings.MonthlyEUI.title, unit = '', label = 'Total Cost Breakdown';
        return (
            <div>

                <div className={" p-6 bg-white shadow rounded corner-flower-"}>
                    <div className="flex items-center justify-between">
                        <h3 className={'small-title overlay-top'}>{title}</h3>
                        <span className="text-2xl font-bold overlay-top">{unit + ' ' + NoDecimalFormat(value)}</span>
                    </div>
                    <TotalCostBreakdownChart
                        height={250}
                        type={'month'}
                        seriesField={'type'}
                        value={'eui'}
                        data={data}
                    />

                </div>

            </div>
        )
    }

    handleCombinedCumulative = (providedData) => {

        const allDates = [...new Set(providedData.map(item => item.date))].sort();

        const defaultDataStructure = type => {
            let rollingValue = 0;
            return allDates.map(date => {
                const existingEntry = providedData.find(item => item.date === date && item.type === type);
                if (existingEntry) {
                    rollingValue = existingEntry.value;
                }
                return {
                    date,
                    value: rollingValue,
                    type
                };
            });
        };

        const filledElectricityData = defaultDataStructure("Electricity");
        const filledGasData = defaultDataStructure("Gas");

        return [...filledElectricityData, ...filledGasData];
    }

    handleMonthlyCumulative = () => {
        let providedData = [...this.state.ElecCumulativeUsage, ...this.state.GasCumulativeUsage];
        const allDates = [...new Set(providedData.map(item => item.date))];

        const defaultDataStructure = type => allDates.map(date => ({
            date,
            value: 0,
            type
        }));

        const defaultElectricityData = defaultDataStructure("Electricity");
        const defaultGasData = defaultDataStructure("Gas");

        const filledElectricityData = this.fillData(defaultElectricityData, this.state.ElecCumulativeUsage);
        const filledGasData = this.fillData(defaultGasData, this.state.GasCumulativeUsage);
        return [...filledElectricityData, ...filledGasData];

    }

    fillData = (defaultData, providedData) => {
        return defaultData.map(defaultItem => {
            const match = providedData.find(
                providedItem => providedItem.date === defaultItem.date && providedItem.type === defaultItem.type
            );
            return match || defaultItem;
        });
    };

    render() {

        let providedCumulativeData = [...this.state.ElecCumulativeUsage, ...this.state.GasCumulativeUsage];
        let cumulative = this.handleCombinedCumulative(providedCumulativeData);
        let providedMonthlyData = [...this.state.ElecMonthlyUsage, ...this.state.GasMonthlyUsage]
        let monthly_usage = providedMonthlyData.sort((a, b) => (a.date > b.date) ? 1 : -1);
        return (

            <Suspense fallback={<Spinner />}>

                <div style={{ padding: '0 10px' }}>
                    <Spin spinning={this.state.isLoading}>
                        <div id={'Report_1'} className={'mt-4'}>

                            <div>
                                <section className="w-full">

                                    <AnalysisFilter
                                        onRender={this.handleRender}
                                        daterange={this.props.daterange}
                                    />


                                    <div>
                                        <div className="flex flex-wrap">
                                            <Col span={24} style={{ margin: '10px 0'}}>

                                                <SavingCharts
                                                    NormalizedElec={{
                                                        data: this.state['ElecData'],
                                                        regression: this.state.ElecReg,
                                                        r_squared: this.state.ElecR_Squared,
                                                        cv: this.state.ElecCv,
                                                    }}
                                                    NormalizedGas={{
                                                        data: this.state['GasData'],
                                                        regression: this.state.GasReg,
                                                        r_squared: this.state.GasR_Squared,
                                                        cv: this.state.GasCv,
                                                    }}
                                                    MonthlyElec={{
                                                        data: this.state.ElecUsage,
                                                    }}
                                                    MonthlyGas={{
                                                        data: this.state.GasUsage,
                                                    }}
                                                    MonthlyCost={{
                                                        data: monthly_usage,
                                                    }}
                                                    CumulativeCost={{
                                                        data: cumulative,
                                                    }}
                                                />
                                            </Col>

                                            <Col span={24}>
                                                <Row className={'w-full'}>
                                                    <Col xs={24} md={24} xl={24} style={{ paddingRight: '5px' }}>
                                                        <div style={{ overflowX: 'auto' }}>
                                                            <div className={'small-title title-truncate pt-4'}>Electricity Monthly Savings</div>
                                                            <AnalysisTableWrapper>
                                                                <Table dataSource={this.state.ElecSummaryData}
                                                                    pagination={false}
                                                                    columns={columns}
                                                                    summary={() => (
                                                                        <Table.Summary.Row>
                                                                            <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={1} />
                                                                            <Table.Summary.Cell index={2}>{NoDecimalFormat(this.state.ElecTotals.Usage)}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={3}>{this.state.ElecTotals.Adjustment}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={4} />
                                                                            <Table.Summary.Cell index={5} />
                                                                            <Table.Summary.Cell index={6} />
                                                                            <Table.Summary.Cell index={7}>{this.state.ElecTotals.ServiceDays}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={8}>{NoDecimalFormat(this.state.ElecTotals.AdjustUsage)}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={9}></Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={10}></Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={11}>{NoDecimalFormat(this.state.ElecTotals.BasicUsage)}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={12}>{NoDecimalFormat(this.state.ElecTotals.SavedUsage)}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={13}>{PercentFormat(this.state.ElecTotals.SavedUsage / this.state.ElecTotals.BasicUsage)}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={14}>{CurrencyFormat(this.state.ElecTotals.CostSavings)}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={15} />
                                                                        </Table.Summary.Row>
                                                                    )}
                                                                />
                                                            </AnalysisTableWrapper>
                                                        </div>
                                                    </Col>
                                                    <Col xs={24} md={24} xl={24}>
                                                        <div style={{ overflowX: 'auto' }}>
                                                            <div className={'small-title title-truncate pt-4'}>Gas Monthly Savings</div>
                                                            <AnalysisTableWrapper>
                                                                <Table dataSource={this.state.GasSummaryData} pagination={false}
                                                                    columns={columns}
                                                                    summary={() => (
                                                                        <Table.Summary.Row>
                                                                            <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={1} />
                                                                            <Table.Summary.Cell index={2}>{NoDecimalFormat(this.state.GasTotals.Usage)}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={3}>{this.state.GasTotals.Adjustment}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={4} />
                                                                            <Table.Summary.Cell index={5} />
                                                                            <Table.Summary.Cell index={6} />
                                                                            <Table.Summary.Cell index={7}>{this.state.GasTotals.ServiceDays}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={8}>{NoDecimalFormat(this.state.GasTotals.AdjustUsage)}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={9}></Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={10}></Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={11}>{NoDecimalFormat(this.state.GasTotals.BasicUsage)}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={12}>{NoDecimalFormat(this.state.GasTotals.SavedUsage)}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={13}>{PercentFormat(this.state.GasTotals.SavedUsage / this.state.GasTotals.BasicUsage)}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={14}>{CurrencyFormat(this.state.GasTotals.CostSavings)}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell index={15} />
                                                                        </Table.Summary.Row>
                                                                    )}
                                                                />
                                                            </AnalysisTableWrapper>
                                                        </div>
                                                    </Col>
                                                </Row>


                                            </Col>

                                        </div>
                                    </div>

                                </section>
                            </div>
                        </div>

                    </Spin></div>


            </Suspense>
        )
            ;
    }
}

const mapStateToProps = state => {
    return {
        Area: _.get(state.Facility.selected, 'record.area'),
        daterange: state.GlobalData.dateRange
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SavingsPage);

import React, {Component} from 'react';
import {Button, Drawer, Popover, Tooltip} from "antd";
import actions from "@redux/EditableTable/actions";
import {connect} from "react-redux";
import {FilterFilled, FilterOutlined, ImportOutlined} from "@ant-design/icons";

import Detail from "@components/Table/Detail";
import _ from "lodash";
import appActions from "@redux/app/actions";
import board from "@assets/images/login.jpg";
import styled from "styled-components";


class PageHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            value: [],
            filter_dates: []
        }
    }

    handleImport = (e) => {
        this.props.onCloseImportModalRequest(false)
    }

    handleSelect = (e) => {
        this.props.handleSelect(e)
    }

    handleRefresh = (e) => {
        this.props.handleFresh()
    }

    handleOpenNew = (e) => {
        this.setState({
            isOpenNew: true
        })
    }

    onClose = (e) => {
        this.setState({
            isOpenNew: false
        })
    }

    handOnlineSave = async (key, row) => {
        let result = await this.props.createRecords(row);
        // console.log('result', result)
        if (!(result?.response?.status > 299)) {
            this.setState({
                isOpenNew: false
            })
            this.props.handleFresh();
        }
    }

    formRefCallback = (formRef) => {
        // console.log('formRefCallback', formRef)
        this.props.getFormRef(formRef);
    }

    renderDrawer = () => {
        return (
            <>
                <Drawer
                    width={window.innerWidth > 900 ? '90%' : '100%'}
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.isOpenNew}
                    destroyOnClose={true}
                    autoFocus={true}
                >
                    <div>
                        {this.state.isOpenNew ?
                            <Detail formFields={this.props.formFields}
                                    handOnlineSave={this.handOnlineSave}
                                    columnProps={this.props.columnProps}
                                    formClassName={this.props.formClassName}
                                    isNewRecords={true}
                                    onClose={this.onClose}
                                    formRefCallback={this.formRefCallback}
                            /> : ''}
                    </div>
                </Drawer>
            </>)
    }


    handleOpenFilters = () => {
        this.setState({
            isOpenFilters: true
        })
    }

    render() {

        return (

            <div className=" -mx-3 items-center w-full">
                <div className=" lg:w-auto flex items-center justify-between px-3">


                    <div>
                        {/* {this.props.filterDescription} */}
                    </div>

                </div>
                <div className=" lg:w-auto ml-auto px-3 flex flex-wrap justify-between mt-2">


                    <div className='flex  filter-item justify-between w-full items-center'>
                        <div className={'flex flex-wrap'}>
                            {typeof this.props.renderFilter !== "undefined" ? this.props.renderFilter() : ''}

                        </div>

                        {!this.props.isHideRefreshNews && <div className={'flex '}>
                            {
                                this.props.renderButtons ? this.props.renderButtons() : null
                            }
                            {this.props.hide_imports ? '' : <Button key="button0" type="primary"
                                                                    className={'inline-flex items-center px-3  font-medium   mr-5'}
                                                                    onClick={this.handleImport}>
                                <ImportOutlined/> Import
                            </Button>}

                            <Button key="button1" type=""
                                    className={'inline-flex items-center px-3  font-medium   mr-5 reload-refresh-btn'}
                                    onClick={this.handleRefresh}>
                                <i className="fas fa-sync pr-3"></i> Refresh
                            </Button>

                            {!this.props.hid_new ? <Button key="button2" type="primary"
                                                           className={'inline-flex items-center px-3  font-medium   '}
                                                           onClick={typeof this.props.onNew === 'function' ? this.props.onNew : this.handleOpenNew}
                            >
                                <i className="fas fa-plus pr-3"></i> {this.props.new_text ? this.props.new_text : 'New'}
                            </Button> : ''}

                            {this.props.toolBarRender && this.props.toolBarRender()}




                            <Popover content={

                                <div className="mt-3 text-center">
                                    <div className="flex justify-between items-center border-b-2 py-2">
                                       <div></div>
                                        <button className="text-blue-600 font-bold uppercase px-6 py-2 rounded" onClick={this.props.resetFilters}>
                                            Reset
                                        </button>
                                    </div>
                                    <div className="mt-2 px-7 py-3">
                                        {/* Content will go here */}
                                        {/* Example section */}
                                        <div className="mb-4">
                                            {typeof this.props.renderSearches !== "undefined" ? this.props.renderSearches() : ''}
                                        </div>
                                        {/* Add additional sections here */}
                                    </div>

                                </div>
                            } trigger="click" placement="bottomRight"
                            >

                                {typeof this.props.resetFilters !== 'undefined'?<Button key="button4" type="text" shape={'circle'}
                                        icon={<FilterFilled className={this.props.filterHasValue?'text-orange-600':'custom-blue'} />}
                                        className={'inline-flex items-center px-3 ml-5 font-medium  filter-btn '}
                                        onClick={this.handleOpenFilters}
                                >
                                </Button>:<div className={'ml-5 px-3'} />}


                            </Popover>

                        </div>}

                    </div>

                </div>
                {this.state.isOpenNew ? this.renderDrawer() : ''}
            </div>

        );
    }
}

const mapStateToProps = state => {
    const role = _.get(state, 'Auth.role', {});
    const Role = role && Object.keys(role)[0];
    return {
        Role: Role,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeCurrent: (e) => dispatch(appActions.changeCurrent(e)),
        OpenDrawer: (e, id) => dispatch(actions.OpenDrawer(e, id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageHeader);


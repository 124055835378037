import React from 'react'
import { Select } from 'antd'
import { useRequest } from 'ahooks'
import { api } from '@/redux/api'

const fetchUsers = (search) => {
    return api.filterCustomUsers(search).then(res => {
        const results = res?.data?.results ?? []
        return results.map(item => {
            return {
                value: item.id,
                label: item.username
            }
        })
    })
}

export const UserSelect = (props) => {
    const { data: users = [], run: searchUsers } = useRequest(fetchUsers, {
        debounceWait: 300,
    })

    const handleSearch = (value) => {
        if(!value) {
            searchUsers()
            return
        }
        searchUsers(`username=${value}`)
    }

    return (
        <Select
            {...props}
            showSearch
            allowClear
            options={users}
            filterOption={false}
            onSearch={handleSearch}
            defaultActiveFirstOption={false}
            notFoundContent={null}
        />
    )
}

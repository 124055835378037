import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  Avatar,
  Button,
  Comment,
  Form,
  Input,
  List,
  Spin,
  Tooltip,
  Badge,
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import _ from "lodash";
import { api } from "@redux/api";
import notification2 from "@components/Notification2";
import "./WorkorderComment.css";
import { EventName, useEventBus } from "@/hooks/useEventBus";

const { TextArea } = Input;

const CommentContent = React.memo(({ comment, userId, onDelete }) => {
  const deleteWorkorderComment = useCallback(() => {
    onDelete(comment.id);
  }, [comment.id, onDelete]);

  const isCurrentUser = comment.createdby === userId;
  const userImage = _.get(comment, "createdby_image");

  return (
    <div
      className={`message-wrapper ${
        isCurrentUser ? "message-right" : "message-left"
      }`}
    >
      <div className="message-avatar">
        <Avatar
          size={40}
          src={userImage}
          icon={!userImage && <i className="fas fa-user" />}
          className={
            isCurrentUser ? "current-user-avatar" : "other-user-avatar"
          }
        />
        <span className="message-author">
          {_.get(comment, "createdby_name")}
        </span>
      </div>
      <div className="message-content">
        <div className="message-bubble">
          <div className="message-text">{_.get(comment, "content")}</div>
        </div>
        <div className="message-footer">
          <Tooltip
            title={moment(_.get(comment, "modified_date")).format(
              "YYYY-MM-DD HH:mm:ss"
            )}
          >
            <span className="message-time">
              {/* {moment(_.get(comment, "modified_date")).fromNow()} */}
              {moment(_.get(comment, "modified_date")).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </Tooltip>
          {isCurrentUser && (
            <Tooltip title="Delete">
              <Button
                type="text"
                icon={<i className="fas fa-trash-alt" />}
                onClick={deleteWorkorderComment}
                className="delete-btn"
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
});

const Editor = React.memo(({ onSubmit }) => {
  const [value, setValue] = useState("");
  const textAreaRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);

  const handleChange = useCallback(
    (e) => {
      setValue(e.target.value);
      // 使用 RAF 确保在下一帧渲染时调整高度

    },
    [isMounted]
  );

  const handleSubmit = useCallback(() => {
    if (!value.trim()) return;
    onSubmit(value);
    setValue("");
    // 重置文本框高度
    if (isMounted && textAreaRef.current?.resizableTextArea?.textArea) {
      textAreaRef.current.resizableTextArea.textArea.style.height = "36px";
    }
  }, [value, onSubmit, isMounted]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  // 组件挂载标记
  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  return (
    <div className="message-editor">
      <div className="message-input-wrapper">
        <TextArea
          ref={textAreaRef}
          autoSize={true}
          onChange={handleChange}
          value={value}
          onKeyPress={handleKeyPress}
          placeholder="Type a message..."
          className="message-input"
          style={{ overflow: 'hidden' }}
        />
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={!value.trim()}
          className="send-button"
          icon={<i className="fas fa-paper-plane" />}
        />
      </div>
    </div>
  );
});

export const WorkorderComment = React.memo(({ id, boxHeight }) => {
  const containerRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);

  const userId = useSelector((state) => state.Auth.userId);

  const getWorkorderComments = useCallback((workorderId) => {
    let isSubscribed = true;

    const fetchComments = async () => {
      try {
        setLoading(true);
        const items = await api.getWorkorderComment(workorderId);

        if (!isSubscribed) return;

        if (_.get(items, "status") === 200) {
          let data = items?.data.results;
          if (data) {
            data.sort(
              (a, b) =>  new Date(a.created_date) - new Date(b.created_date)
            );
          }
          setComments(data ? data : []);

          if (isSubscribed) {
            setTimeout(() => {
              if (messagesEndRef.current && isSubscribed) {
                messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }, 100);
          }
        } else {
          if (isSubscribed) {
            notification2["error"]({
              message: "error",
              description: _.get(items, "response.statusText"),
            });
          }
        }
      } catch (error) {
        if (isSubscribed) {
          console.error(error);
        }
      } finally {
        if (isSubscribed) {
          setLoading(false);
        }
      }
    };

    fetchComments();

    return () => {
      isSubscribed = false;
    };
  }, []);

  useEventBus(EventName.RECIVED_WORKORDER_MESSAGE, (data) => {
    if (id === data.parent_id) {
      if (data.type === 'kpiworkordercomment_created' && !loading) {
        getWorkorderComments(id);
      }
    }
  });

  useEffect(() => {
    let cleanup;
    if (id) {
      cleanup = getWorkorderComments(id);
    }
    return () => {
      if (cleanup) {
        cleanup();
      }
    };
  }, [id, getWorkorderComments]);

  const deleteWorkorderComment = useCallback(
    (commentId) => {
      setLoading(true);
      api
        .deleteWorkorderComment(commentId)
        .then(() => getWorkorderComments(id))
        .finally(() => setLoading(false));
    },
    [id, getWorkorderComments]
  );

  const handleSubmit = useCallback(
    (content) => {
      setLoading(true);
      api
        .createWorkorderComment({ content, workorder: id })
        .then((response) => getWorkorderComments(response.data.workorder))
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    },
    [id, getWorkorderComments]
  );

  return (
    <div className="chat-container">
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: boxHeight ? boxHeight : "70vh",
          }}
        >
          <div className="chat-header">
            <div className="chat-header-content">
              <span className="chat-title">Comments</span>
              <Badge
                count={comments.length}
                size="small"
                style={{ backgroundColor: "#52c41a" }}
              />
            </div>
          </div>
          <div ref={containerRef} className="messages-container">
            {comments.map((comment) => (
              <CommentContent
                key={comment.id}
                comment={comment}
                userId={userId}
                onDelete={deleteWorkorderComment}
              />
            ))}
            <div ref={messagesEndRef} />
          </div>
          <Editor onSubmit={handleSubmit} />
        </div>
      </Spin>
    </div>
  );
});
